.img-div-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.statistics {
  width: 100%;
  border-top: 1px solid #e8eaf6;
  background-color: #760a98;
  padding: 30px 0px;
}

.statistics .display-slider {
  display: none;
}
.statistics .bottom-image-section {
  display: flex;
  justify-content: center;
}
.statistics .bottom-image-section .img-div-section {
  margin: auto 5rem;
}

.statistics .bottom-image-section .img-div-section .image-section {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  margin: auto;
  border: 4px solid #1565c0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statistics .image-section .img-section img {
  height: 33px;
}
.statistics .bottom-image-section .img-div-section .image-section .img-section {
  display: flex;
  justify-content: center;
}
.statistics .bottom-image-section .des-section {
  font-style: normal;
}
.statistics .bottom-image-section .des-section .title {
  font-weight: 600;
  font-size: 23.3265px;
  line-height: 33px;
  letter-spacing: 0.03em;
}
.statistics .bottom-image-section .des-section .sub-title {
  font-weight: 400;
  font-size: 15.1748px;
  line-height: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .top-section {
    margin-bottom: 3rem;
    flex-direction: column-reverse;
  }
  .top-section .left-section {
    width: 75%;
    text-align: center;
  }
  .top-section .left-section .title {
    text-align: center;
  }
  .top-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .top-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .top-section .left-section .sub-title {
    text-align: center;
  }
  .top-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .top-section .left-section .right-drop-section .end-title {
    background-color: #a629f6;
    left: auto;
  }

  .top-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .top-section .right-section {
    width: 95%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .top-section {
    flex-direction: column-reverse;
    margin-bottom: 3rem;
  }
  .top-section .left-section {
    width: 95%;
  }
  .top-section .left-section .title {
    text-align: center;
    font-size: 35px;
    line-height: 47px;
  }
  .top-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .top-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .top-section .left-section .sub-title {
    text-align: center;
    font-size: 11.64px;
    line-height: 15px;
    margin-top: 12px;
  }
  .top-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }

  .top-section .left-section .right-drop-section .set-bottom button {
    padding: 8.5px 30px;
  }

  .top-section .left-section .right-drop-section .set-bottom .button-section {
    display: flex;
    padding: 20px 13px;
  }

  .top-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .top-section .right-section {
    width: 95%;
  }
  .top-section .right-section .img-div {
    max-width: 250px;
    min-width: 100px;
  }
  .statistics {
    text-align: center;
  }
  .statistics .display-card {
    display: none;
  }
  .statistics .display-slider {
    display: block !important;
  }
  .statistics .display-slider .image-section {
    width: 70px;
    height: 70px;
    background: #fff;
    border-radius: 50%;
    margin: auto;
    border: 5px solid #c77cfa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statistics .display-slider .image-section .img-section {
    display: flex;
    justify-content: center;
  }
  .statistics .display-slider .des-section .title {
    font-size: 20px;
  }
  .statistics .display-slider .des-section .sub-title {
    font-size: 15.4017px;
  }
}
