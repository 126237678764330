.newsCard {
  width: auto;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  transition: 130ms all ease-in;
  padding: 18px 30px;
}
.newsCard:hover {
  transform: scale(1.02);
}

.newsBody {
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: 15px;
  border: 1px solid black;
}
.newsBody img {
  width: 100%;
  height: 230px;
}

.newsLogo {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  background-color: #6A1B9A;
  padding: 30px;
  border: 1px solid black;
  border-top: none;
}

.newsLogo img {
  border-radius: 2px;
  height: 50px;
}
@media screen and (min-width: 400px) {
  .newsCard {
    padding: 18px 40px;
  }
}

@media screen and (min-width: 440px) {
  .newsCard {
    padding: 18px 50px;
  }
}

@media screen and (min-width: 470px) {
  .newsCard {
    padding: 18px 60px;
  }
}

@media screen and (min-width: 505px) {
  .newsCard {
    padding: 18px 75px;
  }
}

@media screen and (min-width: 530px) {
  .newsCard {
    padding: 18px 85px;
  }
}
@media screen and (min-width: 568px) {
  .newsCard {
    padding: 18px 18px;
  }
}
@media screen and (min-width: 900px) {
  .newsCard {
    padding: 18px 18px;
  }
  .newsBody img {
    height: 250px;
  }
}

@media screen and (min-width: 1300px) {
  .newsCard {
    padding: 20px 20px;
  }
  .newsBody img {
    height: 320px;
  }
}
