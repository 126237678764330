
.headerTitle {
  margin-top: 4rem;
  width: 80%;
}

.headerTitleBg {
  background: linear-gradient(
    96deg,
    rgba(223, 230, 240, 0.79) -11.27%,
    rgba(255, 255, 255, 0.1) 128.44%
  );
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.headerTitleContainer {
  display: flex;
  padding: 1rem 7rem;
  justify-content: space-between;
  align-items: center;
}

.headerTitleContainer__title {
  color: white;
}

.headerTitleContainer__title > h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.headerTitleContainer__title > h2 {
  font-size: 2rem;
}

.headerTitleContainer > img {
  height: 8rem;
}

.headerComponents {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerComponents__container {
  background: linear-gradient(
    96deg,
    rgba(223, 230, 240, 0.79) -11.27%,
    rgba(255, 255, 255, 0.1) 128.44%
  );
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1rem 2rem;
  min-width: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
}

.headerComponents__container > h3 {
  margin-left: 5px;
}

.instruction {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: justify;
  margin-top: 4rem;
}

.instructionContainer {
  width: 70%;
  background: linear-gradient(
    96deg,
    rgba(223, 230, 240, 0.79) -11.27%,
    rgba(255, 255, 255, 0.1) 128.44%
  );
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: black;
}

.instructionContainer > h3 {
  margin-bottom: 1rem;
  font-size: 1.7rem;
}

@media screen and (max-width: 1500px) {
  .headerTitle {
    width: 80%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 1.5rem 3rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 4rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 10px 10px;
  }

  .headerComponents__container > h3 {
    font-size: 1rem;
  }

  .instruction {
    margin-top: 4rem;
  }

  .instructionContainer {
    padding: 5px 1rem;
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 1rem 3rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 4rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 3px 10px;
  }

  .headerComponents__container > h3 {
    font-size: 1rem;
  }

  .instruction {
    margin-top: 3rem;
  }

  .instructionContainer {
    padding: 5px 1rem;
    width: 80%;
  }
}

@media screen and (max-width: 575px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 0 1.5rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 1.5rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 3px 10px;
  }

  .headerComponents__container > h3 {
    font-size: 0.8rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 5px 0.7rem;
    width: 95%;
  }
}

@media screen and (max-width: 450px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 1rem 1.5rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 2rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 5px 8px;
  }

  .headerComponents__container > h3 {
    font-size: 0.8rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 0.5rem 1rem;
    width: 95%;
  }
  .instructionContainer > h3 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 400px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.2rem;
  }

  .headerTitleContainer {
    padding: 1rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1rem;
  }

  .headerTitleContainer > img {
    height: 4rem;
  }

  .headerComponents {
    margin-top: 2rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 5px 5px;
  }

  .headerComponents__container > h3 {
    font-size: 0.7rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 5px 0.7rem;
    width: 95%;
  }
}

@media screen and (max-width: 360px) {
  .headerTitle {
    width: 95%;
    top: 1rem;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.2rem;
  }

  .headerTitleContainer {
    padding: 1rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1rem;
  }

  .headerTitleContainer > img {
    height: 4rem;
  }

  .headerComponents {
    margin-top: 2rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 5px 5px;
  }

  .headerComponents__container > h3 {
    font-size: 0.7rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 0.5rem 1rem;
    width: 95%;
  }

  .instructionContainer > h3 {
    font-size: 1rem;
  }

  .instructionContainer > p {
    font-size: 0.8rem;
  }
}
