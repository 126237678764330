.footer {
  width: 100%;
  min-height: 50vh;
  background-color: #760A98;
  background-repeat: no-repeat;
  color: #fff;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 5rem;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerLeft > img {
  margin-bottom: 2rem;
  width: 10rem;
  height: auto;
}

.footerMiddle {
  margin-left: 5rem;
  display: flex;
  justify-content: center;
}

.footerMiddle__column {
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerMiddle__column > h1 {
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  color: #f2f2f2;
  border-bottom: 1px solid #d600ff;
}

.footerMiddle__column > a {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #e3e3e3;
}

.footerRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.footerRight__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footerRight__column > h1 {
  font-weight: 500;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.footerRight__columnRow {
  display: flex;
  flex-direction: column;
}

.footerRight__columnRow > a img {
  width: 12rem;
  margin-top: 0.5rem;
  object-fit: contain;
}

.footerRight__row {
  display: flex;
  align-items: flex-start;
  margin-top: 4rem;
  gap: 1rem;
}

.footerRight__row > a .MuiSvgIcon-root {
  font-size: 2rem;
  color: #ffffff;
}

.footerBottom {
  padding-top: 3rem;
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: lightgray;
  font-weight: 500;
  gap: 2rem;
}

.footerBottom > button {
  cursor: pointer;
  background-color: transparent;
  outline-width: 0;
  color: lightgray;
  border: none;
}

@media screen and (max-width: 968px) {
  .footerLeft {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }

  .footerLeft > img {
    margin-bottom: 1rem;
  }
  .footerContainer {
    flex-direction: column;
  }
  .footerMiddle {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-left: 0;
  }

  .footerMiddle__column {
    margin: 0 1rem;
    flex-direction: column;
  }

  .footerMiddle__column > h1 {
    margin-bottom: 1rem;
  }
  .footerMiddle__column > a {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .footerRight__columnRow {
    flex-direction: row;
    gap: 1rem;
  }

  .footerBottom {
    padding: 2rem 1rem 1rem 1rem;
    text-align: center;
  }
  .footerRight__column {
    align-items: center;
  }
  .footerRight__column > h1 {
    font-size: 1.3rem;
  }
  .footerRight__row {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .footerContainer {
    padding-top: 2rem;
  }

  .footerRight__columnRow img {
    width: 10rem;
  }
}

@media screen and (max-width: 400px) {
  .footerRight__columnRow > a img {
    width: 8rem;
  }
}
