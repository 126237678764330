.location {
  background: url("../../../assets/images/auth/SignInBG.png");
  background-repeat: no-repeat;
  height: 100vh;
}
.location .locationCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.location .locationCotainer .locationContainerLeft {
  flex: 0.45;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.location .locationCotainer .locationContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.location .locationCotainer .locationContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.location .locationCotainer .locationContainerRight {
  background-color: white;
  width: 35%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  gap: 1.5rem;
}
.location .locationCotainer .locationContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.location .locationCotainer .locationContainerRight p {
  font-size: 1rem;
  cursor: pointer;
}
.location .locationCotainer .locationContainerRight .autocomplete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 75%;
  margin-top: 2rem;
}

.location
  .locationCotainer
  .locationContainerRight
  .autocomplete
  > .ReactSelect {
  width: 100%;
}

.location .locationCotainer .locationContainerRight .submit__button {
  margin-top: 2rem;
}
.location .locationCotainer .locationContainerRight .submit__button button {
  width: 100% !important;
  margin-bottom: 5px;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.location
  .locationCotainer
  .locationContainerRight
  .locationContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.location .locationCotainer .locationContainerRight .marginer {
  border-bottom: 1px solid gray;
  width: 100%;
}
.location .locationCotainer .locationContainerRight p {
  font-size: 0.8rem;
}
.MuiAutocomplete-root button {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .location .locationCotainer .locationContainerLeft {
    display: none;
  }

  .location .locationCotainer .locationContainerRight {
    width: 80%;
  }
  .location .locationCotainer .locationContainerRight {
    font-size: 1.4rem;
  }
}
