.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerContainer {
  background: url('../../../../assets/images/activity/activityHeaderBG.jpg')
    no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.activityContainer__top-backButton {
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
}

.activityHeaderTitle {
  background: #fff;
  border-radius: 50px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 2rem;
  margin-top: 2rem;
  position: relative;
}

.activityHeaderTitle::after {
  content: '';
  position: absolute;
  left: 10%;
  top: -5rem;
  background: #fff;
  width: 5px;
  height: 5rem;
}

.activityHeaderTitle::before {
  content: '';
  position: absolute;
  top: -5rem;
  right: 10%;
  background: #fff;
  width: 5px;
  height: 5rem;
}

.activityHeaderTitleCenter {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activityHeaderTitleCenter > h1 {
  color: #d311fd;
  font-size: 1.5rem;
}

.activityHeaderTitleCenter > h3 {
  color: #000;
  font-size: 1rem;
}

.activityHeaderTitle > img {
  width: 3rem;
}

.headerContent {
  margin-top: 4rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.headerContentItem {
  display: flex;
  gap: 1rem;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 3rem;
  padding: 0 1rem;
  min-width: 15vw;
  margin-bottom: 1rem;
}

.headerContentItem > img {
  width: 3rem;
}

.headerContent__instruction {
  display: flex;
  padding: 10px 2rem;
  align-items: center;
  margin-top: 3rem;
  width: 73%;
  background: #ffffff;
  border-radius: 10px;
  gap: 2rem;
}

.headerContent__instruction > span {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  color: #2100aa;
  background: #2100aa;
}

.headerContent__instructionRight > h3 {
  margin-bottom: 10px;
}

.headerContentItem > p {
  text-align: center;
}

@media screen and (max-width: 760px) {
  .activityHeaderTitle {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .activityHeaderTitle {
    width: 97%;
    padding: 10px 1rem;
  }
  .activityHeaderTitle > img {
    width: 1.5rem;
  }
  .activityHeaderTitleCenter > h1 {
    color: #d311fd;
    font-size: 1.2rem;
  }

  .activityHeaderTitleCenter > h3 {
    color: #000;
    font-size: 0.8rem;
  }
  .headerContent {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .headerContentItem {
    width: 90%;
    margin-bottom: 1rem;
  }
  .headerContentItem > img {
    width: 2rem;
  }
  .headerContentItem > p {
    font-size: 0.8rem;
  }
  .headerContent__instruction {
    width: 90%;
    margin-top: 1rem;
  }
}
