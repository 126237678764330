.scroller-section {
  background-color: #fce4ec;
  padding-top: 70px;
  padding-bottom: 50px;

}
.scroller-section .slider-top-section .title {
  background-color: #fc4747;
  padding: 4px 20px;
  border-radius: 35px;
  color: black;
  margin-top: 5px;
  margin-bottom: 10px;
}
.bg-color-F5FEFF {
  border: 1px solid black;
  background-color: #f5feff !important;
}
.scroller-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  text-align: center;
}
.scroller-section .title .span-text-color {
  color: #1a237e;
}
.scroller-section .title .color-p-r {
  color: #ae46f5;
}
.scroller-section .slider-content {
  width: 80%;
  margin: 45px auto;
}

.scroller-section .slider-content .slider-section {
  position: relative;
  font-style: normal;

  font-weight: 700;
  font-size: 37px;
  border-radius: 40px;
  padding-top: 20px;
  background-color: #e8eaf6 !important;
}
.scroller-section .slider-content .slider-section .slider-top-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-top-section
  .top-section
  .title {
  font-size: 1.7rem;
  color: white;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-top-section
  .top-section
  .sub-title {
  margin-top: 8px;
}

.scroller-section .slider-content .slider-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
}
.scroller-section .slider-content .slider-section .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
}
.scroller-section .slider-content .slider-section .slider-carousel {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-00BCD4 {
  background-color: #26a69a;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-A025ED-FF0000 {
  background-color: #a025ed;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-1AC4FA-A025ED {
  background-color: #1ac4fa;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-E122E5-A4E522 {
  background-color: #e122e5;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .alice-carousel {
  margin-bottom: 30px;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .alice-carousel
  .alice-carousel__dots {
  display: none;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel-root {
  width: 80%;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .slide
  img {
  width: auto;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  img {
  width: auto;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .carousel-status {
  display: none;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .control-arrow {
  top: 50px;
  bottom: unset;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 50%;
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .control-dots {
  display: none;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .alice-carousel__stage-item
  img {
  height: 178px;
  width: 100%;
}
.scroller-section .slider-content .slider-section .slider-carousel .main-div {
  margin: 10px;
  background-color: white;
  border-radius: 10px;
}
.bg-color-4DD0E1 {
  background-color: #00acc1;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .bottom-section {
  padding: 20px 0px;
  border-radius: 0px 0px 11px 11px;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .bottom-section
  .title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  font-weight: 400;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .img-section {
  padding: 15px 20px 15px 20px;
  border-radius: 11px 11px 0px 0px;
  border: 1px solid black;
  border-bottom: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .img-section
  img {
  display: flex;
}
.scroller-section .slider-content .slider-section .image-section {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.scroller-section .slider-content .slider-section .image-section img {
  object-fit: cover;
  width: 100%;
}
@media (min-width: 1279px) and (max-width: 1620px) {
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .scroller-section .title .color-p-r {
    color: #fa441a;
  }
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-A025ED-FF0000 {
    background-color: #f00;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-1AC4FA-A025ED {
    background-color: #a025ed;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-E122E5-A4E522 {
    background-color: #a4e522;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .scroller-section .title .color-p-r {
    color: #fa441a;
  }
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-A025ED-FF0000 {
    background-color: #f00;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-1AC4FA-A025ED {
    background-color: #a025ed;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-E122E5-A4E522 {
    background-color: #a4e522;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .scroller-section .title {
    font-size: 27px;
  }

  .scroller-section .bottom-section .title {
    font-size: 1.5rem !important;
  }
  .scroller-section .alice-carousel__stage-item img {
    height: 150px !important;
  }
  .scroller-section .slider-content {
    width: 100%;
  }

  .scroller-section
    .slider-content
    .slider-section
    .slider-top-section
    .top-section
    .sub-title {
    width: 90%;
    margin: auto;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000 !important;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-top-section
    .top-section
    .drop-down-section {
    padding: 5px 0px;
    margin-left: 0;
    margin-right: 0;
  }
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 100%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-A025ED-FF0000 {
    background-color: #f00;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-1AC4FA-A025ED {
    background-color: #a025ed;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-E122E5-A4E522 {
    background-color: #a4e522;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
