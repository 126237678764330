.headerContainer {
  width: 100%;
}

.headerLogo {
  width: 100%;
  height: 10vh;
  background: #4002ef;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerLogo > img {
  float: right;
  margin-right: 2rem;
  height: 8vh;
}

.headerCurve {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(../../assets/images/headerCurve.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
  height: 80vh;
}

/* @media screen and (max-width: 1150px) {
  .headerCurve {
    height: 60vh;
  }
} */

@media screen and (max-width: 768px) {
  .headerContainer {
    margin-bottom: 2rem;
  }
  .headerCurve {
    /* height: 60vh; */
    background-size: cover;
  }
}

@media screen and (max-width: 350px) {
  .headerCurve {
    height: 65vh;
    background-size: cover;
  }
}
