.main-section {
  max-width: 90%;
  margin: auto;
  z-index: 2;
  position: relative;
}

.main-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-section .left-section {
  width: 50%;
}
.main-section .left-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 3.3rem;
}
.main-section .left-section .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18.3844px;
  line-height: 29px;
}
.main-section .right-section {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
}
.main-section .right-section .img-div {
  max-width: 550px;
  min-width: 200px;
  position: relative;
}
.main-section .right-section .img-div .gif-div-img {
  width: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  top: 20%;
  left: 72.5%;
}
.main-section .right-section .img-div img {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-section {
    margin-bottom: 3rem;
    flex-direction: column-reverse;
  }
  .main-section .left-section {
    width: 75%;
    text-align: center;
  }
  .main-section .left-section .title {
    text-align: center;
  }
  .main-section .left-section .title .color-1 {
    color: #ffff00;
  }
  .main-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .main-section .left-section .sub-title {
    text-align: center;
  }
  .main-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .main-section .left-section .right-drop-section .end-title {
    background-color: #a629f6;
    left: auto;
  }
  .main-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .main-section .right-section {
    width: 95%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main-section {
    margin-bottom: 3rem;
    flex-direction: column-reverse;
  }
  .main-section .left-section {
    width: 75%;
    text-align: center;
  }
  .main-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .main-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .main-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .main-section .left-section .right-drop-section .end-title {
    background-color: #a213ff;
    left: auto;
  }
  .main-section .left-section .right-drop-section .set-bottom button {
    padding: 13.5px 40px;
  }
  .main-section .left-section .right-drop-section .set-bottom .button-section {
    display: flex;
  }
  .main-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .main-section .right-section {
    width: 95%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .main-section {
    padding-bottom: 3rem;
  }
  .main-section {
    flex-direction: column-reverse;
    margin-bottom: 3rem;
  }
  .main-section .left-section {
    display: flex;
    flex-direction: column;

    width: 95%;
  }
  .main-section .left-section .title {
    text-align: center;
    font-size: 35px;
    line-height: 47px;
  }
  .main-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .main-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .main-section .left-section .sub-title {
    text-align: center;
    font-size: 11.64px;
    line-height: 15px;
    margin-top: 12px;
  }
  .main-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .main-section .left-section .right-drop-section .set-bottom button {
    padding: 8.5px 30px;
  }
  .main-section .left-section .right-drop-section .set-bottom .button-section {
    display: flex;
    padding: 20px 13px;
  }
  .main-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .main-section .left-section .right-drop-section .end-title {
    background-color: #a41bfc;
    left: auto;
    font-size: 1.25rem;
  }
  .main-section .right-section {
    width: 95%;
  }
  .main-section .right-section .img-div {
    max-width: 250px;
    min-width: 100px;
  }
}
.main-download{
  margin: 0 auto;
  margin-top: 1rem;
}