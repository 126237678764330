.color-2DB4FF {
  color: #2db4ff;
}



.bg-color-2DB4FF {
  background-color: #2db4ff;
}

.border-2DB4FF {
  border: 2px solid #2db4ff;
}

.color-AE46F5 {
  color: #ae46f5;
}

.bg-color-AE46F5 {
  background-color: #ae46f5;
}

.border-AE46F5 {
  border: 2px solid #ae46f5;
}

.color-FFFFFF {
  color: #ffffff;
}

.bg-color-FFFFFF {
  background-color: #ffffff;
}

.border-FFFFFF {
  border: 2px solid #ffffff;
}

.color-black {
  color: #000;
}

.bg-color-black {
  background-color: #000;
}

.border-black {
  border: 2px solid #000;
}

.color-EBEBEB {
  color: #ebebeb;
}

.bg-color-EBEBEB {
  background-color: #ebebeb;
}

.border-EBEBEB {
  border: 2px solid #ebebeb;
}

.color-F8F8F8 {
  color: #f8f8f8;
}

.bg-color-F8F8F8 {
  background-color: #f8f8f8;
}

.border-F8F8F8 {
  border: 2px solid #f8f8f8;
}

.color-A212FF {
  color: #a212ff;
}

.bg-color-A212FF {
  background-color: #a212ff;
}

.border-A212FF {
  border: 2px solid #a212ff;
}

.color-E7E2F8 {
  color: #e7e2f8;
}

.bg-color-E7E2F8 {
  background-color: #e7e2f8;
}

.border-E7E2F8 {
  border: 2px solid #e7e2f8;
}

.color-A025ED {
  color: #a025ed;
}

.bg-color-A025ED {
  background-color: #a025ed;
}

.border-A025ED {
  border: 2px solid #a025ed;
}

.color-1AC4FA {
  color: #1ac4fa;
}

.bg-color-1AC4FA {
  background-color: #1ac4fa;
}

.border-1AC4FA {
  border: 2px solid #1ac4fa;
}

.color-E122E5 {
  color: #e122e5;
}

.bg-color-E122E5 {
  background-color: #e122e5;
}

.border-E122E5 {
  border: 2px solid #e122e5;
}

.color-CC7DFF {
  color: #cc7dff;
}

.bg-color-CC7DFF {
  background-color: #cc7dff;
}

.border-CC7DFF {
  border: 2px solid #cc7dff;
}

.color-CA7CFF {
  color: #ca7cff;
}

.bg-color-CA7CFF {
  background-color: #ca7cff;
}

.border-CA7CFF {
  border: 2px solid #ca7cff;
}

.color-FFB504 {
  color: #ffb504;
}

.bg-color-FFB504 {
  background-color: #ffb504;
}

.border-FFB504 {
  border: 2px solid #ffb504;
}

.color-FFEB3B {
  color: #FFEB3B;
}

.bg-color-ffc727 {
  background-color: #ffc727;
}

.border-ffc727 {
  border: 2px solid #ffc727;
}

.color-8d00ee {
  color: #8d00ee;
}

.bg-color-8d00ee {
  background-color: #8d00ee;
}

.border-8d00ee {
  border: 2px solid #8d00ee;
}

.color-944cc3 {
  color: #944cc3;
}

.bg-color-944cc3 {
  background-color: #944cc3;
}

.border-944cc3 {
  border: 2px solid #944cc3;
}

.span-text-color {
  color: #8d00ee;
}

button {
  outline: none;
  border: 0;
  cursor: pointer;
}
button:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 10px 0px;
}

.alice-carousel .alice-carousel__dots {
  margin: 5px 0px 16px 0px;
  display: flex;
  justify-content: center;
}
.alice-carousel .alice-carousel__prev-btn {
  position: absolute;
  left: -25px;
  top: 35%;
  width: auto;
  font-size: 2rem;
}
.alice-carousel .alice-carousel__next-btn {
  position: absolute;
  right: -25px;
  top: 35%;
  width: auto;
  font-size: 2rem;
}



.common-header {
  position: sticky;
  top: 0;
  background-color: #ca7cff;
}
.common-header .header-none {
  display: none;
}
.common-header .header-block {
  display: block;
}

.common-footer {
  background-image: linear-gradient(
    to top,
    #a212ff,
    #a212ff,
    #a212ff,
    #a212ff,
    #a212ff,
    #a624ff,
    #a930ff,
    #ad3aff,
    #b54dff,
    #bc5eff,
    #c36dff,
    #ca7cff
  );
}

.text-center {
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .span-text-color {
    color: #fa441a;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .span-text-color {
    color: #fa441a;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .span-text-color {
    color: #fa441a;
  }
}
