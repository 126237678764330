.footer-link-section {
  position: relative;
  height: 100%;
  background-color: #6A1B9A;
}
.footer-link-section .image-section {
  display: flex;
}
.footer-link-section .image-section img {
  width: 100%;
}
.footer-link-section .footer-link-content {
  position: absolute;
  top: 0;
  width: 100%;
}
.footer-link-section .footer-link-content .link-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-link-section .footer-link-content .link-section .link-div {
  margin: auto 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.footer-link-section .footer-link-content .link-section .dot {
  height: 8px;
  width: 8px;
  background-color: #fff;
  border-radius: 50%;
}
.footer-link-section .footer-link-content .social-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.footer-link-section .footer-link-content .social-section .link-div {
  margin: auto 15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.footer-link-section .footer-link-content .social-section .link-div svg {
  fill: #fff;
}
.footer-link-section .footer-link-content .social-section .link-div:hover {
  background-color: #fff !important;
}
.footer-link-section .footer-link-content .social-section .link-div:hover svg {
  fill: blueviolet !important;
  stroke: blueviolet !important;
}
.footer-link-section .footer-link-content .social-section .insta-link {
  margin: auto 15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.footer-link-section .footer-link-content .social-section .insta-link:hover {
  background-color: #fff !important;
}
.footer-link-section .footer-link-content .social-section .insta-link:hover svg {
  color: blueviolet !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .link-div {
    font-size: 10px;
    line-height: 12px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}
@media (min-width: 100px) and (max-width: 480px) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .link-div {
    font-size: 10px;
    line-height: 12px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content .social-section .link-div {
    width: 24px;
    height: 24px;
 }
  .footer-link-section .footer-link-content .social-section .link-div svg {
    width: 11px;
 }
  .footer-link-section .footer-link-content .social-section .insta-link {
    width: 24px;
    height: 24px;
 }
  .footer-link-section .footer-link-content .social-section .insta-link svg {
    width: 11px;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}
