.education-section {
  width: 100%;
  background-color: #6a1b9a;
  color: white;
  margin: auto;
  padding-bottom: 50px;
  padding-top: 40px;
}

.education-section .span-text-color {
  color: #ffc727;
}
.education-section .education-content-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.education-section .education-content-section .left-section {
  width: 35%;
  margin: auto;
}
.education-section .education-content-section .left-section img {
  object-fit: cover;
  width: 100%;
}
.education-section .education-content-section .slider-section {
  display: none;
}
.education-section .education-content-section .right-section {
  width: 40%;
  margin: auto;
}
.education-section .education-content-section .right-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 76px;
}
.education-section
  .education-content-section
  .right-section
  .title
  .color-AE46F5-FA441A {
  color: #ae46f5;
}
.education-section .education-content-section .right-section .des {
  font-style: normal;
  font-weight: 400;
  font-size: 18.64px;
  line-height: 36px;
}
.education-section .education-content-section .right-section .button-section {
  margin-top: 20px;
}
.education-section
  .education-content-section
  .right-section
  .button-section
  .bg-color-FFC727-F96763 {
  background-color: #ffc727;
  color: #fff;
}
.education-section
  .education-content-section
  .right-section
  .button-section
  .bg-color-8D00EE-F96763 {
  background-color: #ffeb3b;
  font-size: 1.2rem;
  color: black !important;
}

.button-readmore {
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  font-size: 26px;
  line-height: 37px;
  border-radius: 25px;
  padding: 15px 45px;
  transition: all 60ms ease-in;
}

.button-readmore:hover {
  background: #f8e005 !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .education-section .education-content-section {
    display: block;
  }
  .education-section .education-content-section .left-section {
    width: 90%;
  }
  .education-section .education-content-section .slider-section {
    display: none;
  }
  .education-section .education-content-section .right-section {
    width: 90%;
    text-align: center;
  }
  .education-section
    .education-content-section
    .right-section
    .title
    .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .education-section .education-content-section .right-section .button-section {
    display: flex;
    justify-content: center;
  }
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-FFC727-F96763,
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-8D00EE-F96763 {
    background-color: #f96763;
    color: #fff;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .education-section .education-content-section {
    display: block;
  }
  .education-section .education-content-section .left-section {
    width: 90%;
  }
  .education-section .education-content-section .slider-section {
    display: none;
  }
  .education-section .education-content-section .right-section {
    width: 90%;
    text-align: center;
  }
  .education-section
    .education-content-section
    .right-section
    .title
    .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .education-section .education-content-section .right-section .button-section {
    display: flex;
    justify-content: center;
  }
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-FFC727-F96763,
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-8D00EE-F96763 {
    background-color: #f96763;
    color: #fff;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .education-section .education-content-section {
    display: block;
  }
  .education-section .education-content-section .left-section {
    width: 90%;
    display: none;
  }
  .education-section .education-content-section .slider-section {
    display: block;
  }
  .education-section
    .education-content-section
    .slider-section
    .alice-carousel__stage-item {
    width: 100% !important;
  }
  .education-section .education-content-section .slider-section .img-section {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .education-section
    .education-content-section
    .slider-section
    .img-section
    img {
    width: 100%;
    object-fit: cover;
  }
  .education-section .education-content-section .right-section {
    width: 90%;
    text-align: center;
  }
  .education-section .education-content-section .right-section .title {
    font-size: 27px;
    line-height: 44px;
  }
  .education-section
    .education-content-section
    .right-section
    .title
    .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .education-section .education-content-section .right-section .des {
    font-size: 11px;
    line-height: 16px;
  }
  .education-section .education-content-section .right-section .button-section {
    display: flex;
    justify-content: center;
    font-size: 11px;
    line-height: 16px;
  }
  .button-readmore {
    padding: 1px 18px;
  }
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-FFC727-F96763,
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-8D00EE-F96763 {
    background-color: #f96763;
    color: #fff;
  }
}
