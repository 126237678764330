.talk-section-content {
  position: relative;
}
.talk-section .span-text-color {
  color: #7b1fa2;
}
.talk-section-content .talk-section {
  background-color: #FCE4EC;

  background-size: cover;
  text-align: center;
  position: relative;
  padding-bottom: 3%;
}

.talk-section-content .talk-section .title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.talk-section-content .talk-section .title-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
}
.talk-section-content .talk-section .title-section .title .color-AE46F5-FA441A {
  color: #ae46f5;
}
.talk-section-content .talk-section .title-section .sub-title {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
}
.talk-section-content .talk-section .alice-carousel {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.talk-section-content .talk-section .slider-section .card {
  background-color: #e8eaf6 !important;
  min-height: 45vh;
  border: 1px solid #0d47a1;
  border-radius: 15px;
  padding: 15px;
  margin-left: 25px;
  height: fit-content;
  margin-top: 85px;
  background-color: #fff;
  padding-top: 53px;
  margin-bottom: 10px;
  border-radius: 15px;
  position: relative;
}
.talk-section-content .talk-section .slider-section .card .main-image-section {
  width: 138px;
  margin: auto;
  position: absolute;
  top: -18%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .main-image-section
  .play-image-section {
  width: 40px;
  margin: auto;
  position: absolute;
  top: 45%;
  left: 34%;
  cursor: pointer;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .main-image-section
  .image-section {
  display: flex;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .main-image-section
  .image-section
  img {
  object-fit: cover;
  width: 100%;
}
.talk-section-content .talk-section .slider-section .card .card-des .title {
  font-style: normal;
  font-weight: 700;
  font-size: 25.64px;
  line-height: 36px;
  padding-top: 25px;
  padding-bottom: 8px;
}
.talk-section-content .talk-section .slider-section .card .card-des .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18.64px;
  line-height: 26px;
  padding-bottom: 15px;
}
.talk-section-content .talk-section .slider-section .card .card-des .des-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14.64px;
  line-height: 26px;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .card-des
  .card-star-img {
  position: absolute;
  bottom: 1rem;
  left: 30%;
}
.talk-section-content .title-bg-img-section {
  display: flex;
  justify-talk-section-content: center;
  position: absolute;
  top: -135px;
}
.talk-section-content .title-bg-img-section img {
  width: 100%;
}
.slider-section {
  margin-top: 15px;
}

@media (min-width: 1441px) and (max-width: 2260px) {
  .talk-section-content .talk-section {
    padding-top: 6rem;
  }
}
@media screen and (max-width: 968px) {
  .talk-section-content .alice-carousel__wrapper {
    padding: 0;
  }
  .talk-section-content .talk-section .title-section .sub-title {
    font-size: 1.1rem;
  }

  .talk-section-content .talk-section .slider-section .card{
    height: 370px;
  }
}
@media (min-width: 1250px) and (max-width: 1440px) {
  .talk-section-content .talk-section {
    padding-top: 11rem;
  }
  .talk-section-content .talk-section .slider-section .card {
    margin-top: 100px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 68px 10px 10px 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -15%;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 686px) and (max-width: 1250px) {
  .talk-section-content .talk-section {
    margin-bottom: 0;
    padding-top: 95px;
    margin-left: 0;
    width: 100%;
  }
  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }
  .talk-section-content .talk-section .slider-section .card {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -13%;
  }
  .talk-section-content .talk-section .left-img-section {
    bottom: -4%;
    left: -205px;
    max-width: 200px;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 568px) and (max-width: 686px) {
  .talk-section-content .talk-section {
    margin-bottom: 0;
    width: 100%;
    padding-top: 60px;
  }

  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }

  .talk-section-content .talk-section .slider-section .card {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -10%;
  }
  .talk-section-content .talk-section .slider-section .card .card-des .title {
    font-size: 0.8rem;
    line-height: 15px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .sub-title {
    font-size: 8px;
    line-height: 12px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .des-title {
    line-height: 13px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img {
    margin-top: 0;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img
    img {
    width: 25px;
  }
  .talk-section-content .talk-section .left-img-section {
    bottom: -13%;
    left: -3%;
    max-width: 200px;
    display: none;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 480px) and (max-width: 568px) {
  .talk-section-content .talk-section {
    margin-bottom: 0;
    padding-top: 50px;
    margin-left: 0;
    width: 100%;
  }
  .talk-section-content .talk-section .title-section {
    margin-bottom: 0rem;
  }

  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }

  .talk-section-content .talk-section .slider-section .card {
    width: 100%;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -14%;
  }
  .talk-section-content .talk-section .slider-section .card .card-des .title {
    font-size: 0.8rem;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .sub-title {
    font-size: 8px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .des-title {
    font-size: 15px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img {
    margin-top: 1.5rem;
  }
  .talk-section-content .talk-section .left-img-section {
    bottom: -5%;
    left: -3%;
    max-width: 120px;
    display: none;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 0px) and (max-width: 480px) {
  .talk-section-content .alice-carousel__stage-item {
    width: 100% !important;
  }
  .talk-section-content .talk-section {
    margin-bottom: 0;
    padding-top: 35px;
    margin-left: 0;
    width: 100%;
  }
  .talk-section-content .talk-section .title-section {
    margin-bottom: 0rem;
  }

  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }

  .talk-section-content .talk-section .slider-section .card {
    margin-left: 10px;
    margin-right: 10px;
    padding: 68px 10px 10px 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -10%;
  }
  .talk-section-content .talk-section .slider-section .card .card-des .title {
    margin-top: 2rem;
    font-size: 0.8rem;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .sub-title {
    font-size: 8px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .des-title {
    font-size: 0.8rem;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img {
    margin-top: 1.5rem;
  }
  .talk-section-content .talk-section .left-img-section {
    display: none;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
