.popUpContainer {
  border-radius: 9px;
  border: 1px solid rgb(235, 235, 235);
  background-color: #fcfcfc;
  z-index: 2;
  width: 30rem;
  min-height: 70vh;
  overflow-y: scroll;
  pointer-events: none;
}

.popUpContainer::before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-width: 1px 0px 0px 1px;
  border-top-style: solid;
  border-left-style: solid;
  border-top-color: rgb(235, 235, 235);
  border-left-color: rgb(235, 235, 235);
  border-image: initial;
  transform: rotate(45deg) translateX(-50%);
  top: 2.8rem;
  left: 75%;
  border-right-style: initial;
  border-right-color: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  background-color: #fcfcfc;
}

.popUpContainer__box {
  color: rgb(21, 21, 21);
  font-size: 1rem;
  padding: 1.5rem 10px;
  overflow-y: scroll;
}

.notification__row {
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.notification__row:last-child {
  border-bottom: none;
}

.notification__row > img {
  width: 4rem;
  height: 4rem;
  border-radius: 9px;
}

.notification__rowItem {
  margin-left: 1rem;
}

.notification__rowItem > h2 {
  font-size: 0.9rem;
}

.notification__rowItem > p {
  font-size: 0.8rem;
  margin: 0.2rem 0;
}

.notification__rowItem > span {
  font-size: 0.8rem;
}
