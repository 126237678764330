.phone {
  background: url("../../../assets/images/auth/SignInBG.png");
  background-repeat: no-repeat;
  height: 100vh;
}
.phone .phoneCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.phone .phoneCotainer .phoneContainerLeft {
  flex: 0.45;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.phone .phoneCotainer .phoneContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.phone .phoneCotainer .phoneContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.phone .phoneCotainer .phoneContainerRight {
  background-color: white;
  width: 35%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  gap: 2rem;
}
.phone .phoneCotainer .phoneContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.phone .phoneCotainer .phoneContainerRight > p {
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.phone .phoneCotainer .phoneContainerRight input {
  width: 80%;
  margin-bottom: 1rem;
}

.phone .phoneCotainer .phoneContainerRight button {
  width: 80% !important;
  margin: 2rem 0;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom button {
  width: 100% !important;
  margin: 2rem 0;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.phone
  .phoneCotainer
  .phoneContainerRight
  .phoneContainerRightBottom
  .marginer {
  border-bottom: 1px solid gray;
  margin: 0.5rem 0;
  width: 100%;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom p {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .phone .phoneCotainer .phoneContainerLeft {
    display: none;
  }

  .phone .phoneCotainer .phoneContainerRight {
    width: 80%;
  }
  .phone .phoneCotainer .phoneContainerRight h1 {
    font-size: 1.4rem;
  }
}

