.newsCarouselContainer {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  background-color: #FCE4EC;
  justify-content: center;
  padding-bottom: 50px;
}

.carouselTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 27px;
  text-align: center;
}

.carouselTitle span {
  color: #8d00ef;
}
.aliceCarouselWrapper {
  width: 80%;
  margin: 0 auto;
}

@media screen and (min-width: 600px) {
  .newsCarouselContainer {
    padding-top: 110px;
    padding-bottom: 90px;
  }
  .carouselTitle {
    font-size: 2.2rem;
    text-align: center;
  }
}
@media screen and (min-width: 1300px) {
  .aliceCarouselWrapper {
    width: 95%;
  }
}
