@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: auto;
}

a {
  text-decoration: none;
}
.color-1A1A1A {
  color: #1a1a1a;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.cursor-pointer {
  cursor: pointer;
}
html,
body {
  overflow-x: clip;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to right bottom, #c8c7ca, #dbe5f0);
  border-radius: 25px;
}

.skeleton {
  opacity: 0.9;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-position: 0% 50%;
    background-color: hsl(0, 0%, 100%);
  }
  50% {
    background-position: 100% 50%;
    background-color: hsl(200, 2%, 90%);
  }
  100% {
    background-position: 0% 50%;
    background-color: hsl(200, 20%, 95%);
  }
}

.sweet-loading {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.custom_fc_frame {
  right: 30px !important;
  bottom: 50px !important;
}

@media screen and (max-width: 1296px) {
  .custom_fc_frame {
    right: 10px !important;
    bottom: 100px !important;
  }
}

.invisible {
  visibility: hidden;
}

.alice-carousel__dots-item.__active {
  background-color: #760a98 !important;
}
.alice-carousel__dots-item.__inactive {
  background-color: grey !important;
}
.button-solid {
  margin: 0 1rem !important;
}

.headerContainer {
  width: 100%;
}

.headerLogo {
  width: 100%;
  height: 10vh;
  background: #4002ef;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerLogo > img {
  float: right;
  margin-right: 2rem;
  height: 8vh;
}

.headerCurve {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(/static/media/headerCurve.25cb9eaa.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100vw;
  height: 80vh;
}

/* @media screen and (max-width: 1150px) {
  .headerCurve {
    height: 60vh;
  }
} */

@media screen and (max-width: 768px) {
  .headerContainer {
    margin-bottom: 2rem;
  }
  .headerCurve {
    /* height: 60vh; */
    background-size: cover;
  }
}

@media screen and (max-width: 350px) {
  .headerCurve {
    height: 65vh;
    background-size: cover;
  }
}


.headerTitle {
  margin-top: 4rem;
  width: 80%;
}

.headerTitleBg {
  background: linear-gradient(
    96deg,
    rgba(223, 230, 240, 0.79) -11.27%,
    rgba(255, 255, 255, 0.1) 128.44%
  );
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

.headerTitleContainer {
  display: flex;
  padding: 1rem 7rem;
  justify-content: space-between;
  align-items: center;
}

.headerTitleContainer__title {
  color: white;
}

.headerTitleContainer__title > h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.headerTitleContainer__title > h2 {
  font-size: 2rem;
}

.headerTitleContainer > img {
  height: 8rem;
}

.headerComponents {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerComponents__container {
  background: linear-gradient(
    96deg,
    rgba(223, 230, 240, 0.79) -11.27%,
    rgba(255, 255, 255, 0.1) 128.44%
  );
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1rem 2rem;
  min-width: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
}

.headerComponents__container > h3 {
  margin-left: 5px;
}

.instruction {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: justify;
  margin-top: 4rem;
}

.instructionContainer {
  width: 70%;
  background: linear-gradient(
    96deg,
    rgba(223, 230, 240, 0.79) -11.27%,
    rgba(255, 255, 255, 0.1) 128.44%
  );
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: black;
}

.instructionContainer > h3 {
  margin-bottom: 1rem;
  font-size: 1.7rem;
}

@media screen and (max-width: 1500px) {
  .headerTitle {
    width: 80%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 1.5rem 3rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 4rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 10px 10px;
  }

  .headerComponents__container > h3 {
    font-size: 1rem;
  }

  .instruction {
    margin-top: 4rem;
  }

  .instructionContainer {
    padding: 5px 1rem;
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 1rem 3rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 4rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 3px 10px;
  }

  .headerComponents__container > h3 {
    font-size: 1rem;
  }

  .instruction {
    margin-top: 3rem;
  }

  .instructionContainer {
    padding: 5px 1rem;
    width: 80%;
  }
}

@media screen and (max-width: 575px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 0 1.5rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 1.5rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 3px 10px;
  }

  .headerComponents__container > h3 {
    font-size: 0.8rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 5px 0.7rem;
    width: 95%;
  }
}

@media screen and (max-width: 450px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.5rem;
  }

  .headerTitleContainer {
    padding: 1rem 1.5rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1.2rem;
  }

  .headerTitleContainer > img {
    height: 6rem;
  }

  .headerComponents {
    margin-top: 2rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 5px 8px;
  }

  .headerComponents__container > h3 {
    font-size: 0.8rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 0.5rem 1rem;
    width: 95%;
  }
  .instructionContainer > h3 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 400px) {
  .headerTitle {
    width: 95%;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.2rem;
  }

  .headerTitleContainer {
    padding: 1rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1rem;
  }

  .headerTitleContainer > img {
    height: 4rem;
  }

  .headerComponents {
    margin-top: 2rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 5px 5px;
  }

  .headerComponents__container > h3 {
    font-size: 0.7rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 5px 0.7rem;
    width: 95%;
  }
}

@media screen and (max-width: 360px) {
  .headerTitle {
    width: 95%;
    top: 1rem;
  }

  .headerTitleContainer__title > h1 {
    font-size: 1.2rem;
  }

  .headerTitleContainer {
    padding: 1rem;
  }

  .headerTitleContainer__title > h2 {
    font-size: 1rem;
  }

  .headerTitleContainer > img {
    height: 4rem;
  }

  .headerComponents {
    margin-top: 2rem;
  }

  .headerComponents__container {
    border-radius: 20px;
    padding: 5px 5px;
  }

  .headerComponents__container > h3 {
    font-size: 0.7rem;
  }

  .instruction {
    margin-top: 2rem;
  }

  .instructionContainer {
    padding: 0.5rem 1rem;
    width: 95%;
  }

  .instructionContainer > h3 {
    font-size: 1rem;
  }

  .instructionContainer > p {
    font-size: 0.8rem;
  }
}

.footer {
  width: 100%;
  min-height: 50vh;
  background-color: #760A98;
  background-repeat: no-repeat;
  color: #fff;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 5rem;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerLeft > img {
  margin-bottom: 2rem;
  width: 10rem;
  height: auto;
}

.footerMiddle {
  margin-left: 5rem;
  display: flex;
  justify-content: center;
}

.footerMiddle__column {
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footerMiddle__column > h1 {
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  color: #f2f2f2;
  border-bottom: 1px solid #d600ff;
}

.footerMiddle__column > a {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  text-decoration: none;
  color: #e3e3e3;
}

.footerRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.footerRight__column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footerRight__column > h1 {
  font-weight: 500;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.footerRight__columnRow {
  display: flex;
  flex-direction: column;
}

.footerRight__columnRow > a img {
  width: 12rem;
  margin-top: 0.5rem;
  object-fit: contain;
}

.footerRight__row {
  display: flex;
  align-items: flex-start;
  margin-top: 4rem;
  grid-gap: 1rem;
  gap: 1rem;
}

.footerRight__row > a .MuiSvgIcon-root {
  font-size: 2rem;
  color: #ffffff;
}

.footerBottom {
  padding-top: 3rem;
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: lightgray;
  font-weight: 500;
  grid-gap: 2rem;
  gap: 2rem;
}

.footerBottom > button {
  cursor: pointer;
  background-color: transparent;
  outline-width: 0;
  color: lightgray;
  border: none;
}

@media screen and (max-width: 968px) {
  .footerLeft {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }

  .footerLeft > img {
    margin-bottom: 1rem;
  }
  .footerContainer {
    flex-direction: column;
  }
  .footerMiddle {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-left: 0;
  }

  .footerMiddle__column {
    margin: 0 1rem;
    flex-direction: column;
  }

  .footerMiddle__column > h1 {
    margin-bottom: 1rem;
  }
  .footerMiddle__column > a {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .footerRight__columnRow {
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .footerBottom {
    padding: 2rem 1rem 1rem 1rem;
    text-align: center;
  }
  .footerRight__column {
    align-items: center;
  }
  .footerRight__column > h1 {
    font-size: 1.3rem;
  }
  .footerRight__row {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .footerContainer {
    padding-top: 2rem;
  }

  .footerRight__columnRow img {
    width: 10rem;
  }
}

@media screen and (max-width: 400px) {
  .footerRight__columnRow > a img {
    width: 8rem;
  }
}

.MuiSelect-select {
  -webkit-appearance: none;
          appearance: none;
  padding: 0.25em 0.5em !important;
  color: white;
  background-color: transparent;
  border: none;
  width: 100%;
  font-family: inherit;
  font-size: 1rem !important;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  overflow-y: hidden;
}

.select.selectBlack > select {
  color: gray;
  width: 100%;
  font-size: 0.8rem;
}

.MuiSelect-icon {
  color: #fff !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.select {
  padding: 0.25em 0.5em;
  border: 2px solid #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: 10px;
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  transition: all 0.4s ease-in-out;
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0;
}

.select.selectBlack {
  padding: 7px 10px;
  border: 1px solid gray;
  width: 100%;
  margin-bottom: 0px;
}

select,
.select:after {
  grid-area: select;
}

.select::after {
  content: '';
  width: 0.7em;
  height: 0.4em;
  background-color: white;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

.select.selectBlack::after {
  content: '';
  width: 0.7em;
  height: 0.4em;
  background-color: #6a5eea;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

select > option {
  width: 100%;
  color: black;
  font-size: 0.9rem;
  background-color: rgb(231, 230, 230);
  border-radius: 20px;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

@media screen and (max-width: 500px) {
  .MuiSelect-select {
    width: 100%;
    font-size: 0.7rem;
  }
}

.formikselect > select {
  -webkit-appearance: none;
          appearance: none;
  color: white;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  transition: all 0.4s ease-in-out;
}

.formikselect.formikselectBlack > select {
  color: gray;
  width: 100%;
  font-size: 0.8rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.formikselect {
  padding: 0.25em 0.5em;
  border: 3px solid #37d0af;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: 10px;
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  transition: all 0.4s ease-in-out;
}

.formikselect.formikselectBlack {
  padding: 7px 10px;
  border: 1px solid gray;
  width: 100%;
  margin-bottom: 0px;
}

select,
.formikselect:after {
  grid-area: select;
}

.formikselect::after {
  content: '';
  width: 0.7em;
  height: 0.4em;
  background-color: white;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

.formikselect.formikselectBlack::after {
  content: '';
  width: 0.7em;
  height: 0.4em;
  background-color: #6a5eea;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

select > option {
  width: 100%;
  color: black;
  font-size: 0.9rem;
  /* background-color: rgb(231, 230, 230); */
  border-radius: 20px;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.select {
  font-weight: 700;
  color: black;
  text-align: center;
}

.css-tj5bde-Svg {
  color: black;
}

.css-2613qy-menu {
  z-index: 999;
  min-height: 100%;
  /* max-width: 100%; */
}

.htmlInput__input {
  outline-width: 0;
  border: 1px solid gray;
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;
}

.passwordField {
  outline-width: 0;
  border: 1px solid gray;
  padding: 8px 20px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}

.passwordField__input {
  outline-width: 0;
  border: none;
  flex: 1 1;
}

.passwordField > .MuiSvgIcon-root {
  font-size: medium;
  color: #6a5eea;
  cursor: pointer;
}

.htmlTextArea__input {
  outline-width: 0;
  border: 1px solid gray;
  padding: 8px 20px;
  border-radius: 8px;
  width: 100%;
}

.memberCard {
  width: 20rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(33 42 57 / 18%) 0px 12px 12px -8px,
    rgb(33 42 57 / 8%) 0px -2px 12px -2px;
  border-radius: 20px;
  margin: 0 0 10px 0;
}

.memberCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.memberCardContainer {
  position: relative;
  /* width: 17rem; */
}

.memberCardContainer__Content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.memberCardContainer:hover .memberCardContainer__Content {
  opacity: 1;
}

.memberCardContainer > img {
  width: 20rem;
  height: 50vh;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 1s ease-in-out;
}

.memberCardContainer__Content > p {
  font-weight: 500;
  margin: 0.15rem 0;
  text-align: center;
  color: gray;
}

.memberCardBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 10px;
}

.memberCardBottom > h1 {
  font-weight: 700;
  margin: 0.15rem 0;
  font-size: 1.1rem;
  color: black;
}

@media screen and (max-width: 1200px) {
  .memberCard {
    height: 100%;
    width: 300px;
  }
  .memberCardContainer > img {
    width: 300px;
    height: auto;
    border-radius: 25px;
  }
  .memberCardContainer__Content > p {
    font-size: 0.7rem;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .memberCard {
    width: 200px;
    height: 100%;
  }
  .memberCardContainer > img {
    width: 200px;
    height: auto;
    border-radius: 25px;
  }
  .memberCardContainer__Content > p {
    font-size: 0.7rem;
    text-align: center;
  }

  .memberCardBottom > h1 {
    font-size: 0.9rem;
  }

  .memberCardBottom > p {
    font-size: 0.7rem;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  /* z-index: 100; */
  background: white !important;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  width: 25vw;
  min-height: 50vh;
  border-radius: 26px;
}

.modal-bg {
  padding: 1rem;
  border-radius: 26px;
  z-index: 1200;
  background: url(/static/media/popupBg.17ee9aee.svg);
  background-size: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.5;
  cursor: pointer;
  border: none;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.2rem 0.6rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}

.modal-body {
  margin-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-body p {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  text-align: center;
  margin-top: 0.6rem;
}

.modal-body > button,
.modal-button {
  /* position: absolute; */
  /* bottom: 1rem; */
  color: #fff;
  text-align: center;
  margin-top: 1.5rem;
  border: none;
  border-radius: 3px;
  padding: 1rem 2rem;
  width: 50%;
  background: linear-gradient(267.1deg, #a310fd -7.31%, #d311fd 109.39%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
}

@media screen and (max-width: 1296px) {
  .modal {
    width: 50vw;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    width: 70vw;
  }
}

@media screen and (max-width: 500px) {
  .modal {
    width: 90vw;
  }
}

@media screen and (max-width: 360px) {
  .modal {
    width: 90vw;
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerContainer {
  background: url(/static/media/activityHeaderBG.27e50abb.jpg)
    no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.activityContainer__top-backButton {
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;
}

.activityHeaderTitle {
  background: #fff;
  border-radius: 50px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 2rem;
  margin-top: 2rem;
  position: relative;
}

.activityHeaderTitle::after {
  content: '';
  position: absolute;
  left: 10%;
  top: -5rem;
  background: #fff;
  width: 5px;
  height: 5rem;
}

.activityHeaderTitle::before {
  content: '';
  position: absolute;
  top: -5rem;
  right: 10%;
  background: #fff;
  width: 5px;
  height: 5rem;
}

.activityHeaderTitleCenter {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activityHeaderTitleCenter > h1 {
  color: #d311fd;
  font-size: 1.5rem;
}

.activityHeaderTitleCenter > h3 {
  color: #000;
  font-size: 1rem;
}

.activityHeaderTitle > img {
  width: 3rem;
}

.headerContent {
  margin-top: 4rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.headerContentItem {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 3rem;
  padding: 0 1rem;
  min-width: 15vw;
  margin-bottom: 1rem;
}

.headerContentItem > img {
  width: 3rem;
}

.headerContent__instruction {
  display: flex;
  padding: 10px 2rem;
  align-items: center;
  margin-top: 3rem;
  width: 73%;
  background: #ffffff;
  border-radius: 10px;
  grid-gap: 2rem;
  gap: 2rem;
}

.headerContent__instruction > span {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  color: #2100aa;
  background: #2100aa;
}

.headerContent__instructionRight > h3 {
  margin-bottom: 10px;
}

.headerContentItem > p {
  text-align: center;
}

@media screen and (max-width: 760px) {
  .activityHeaderTitle {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .activityHeaderTitle {
    width: 97%;
    padding: 10px 1rem;
  }
  .activityHeaderTitle > img {
    width: 1.5rem;
  }
  .activityHeaderTitleCenter > h1 {
    color: #d311fd;
    font-size: 1.2rem;
  }

  .activityHeaderTitleCenter > h3 {
    color: #000;
    font-size: 0.8rem;
  }
  .headerContent {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .headerContentItem {
    width: 90%;
    margin-bottom: 1rem;
  }
  .headerContentItem > img {
    width: 2rem;
  }
  .headerContentItem > p {
    font-size: 0.8rem;
  }
  .headerContent__instruction {
    width: 90%;
    margin-top: 1rem;
  }
}

 .gender {
	 background: url(/static/media/SignInBG.83fcd96c.png);
	 background-repeat: no-repeat;
	 width: 100vw;
	 height: 100vh;
}
 .gender .genderCotainer {
	 width: 100%;
	 height: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 5%;
	 gap: 5%;
}
 .gender .genderCotainer .genderContainerLeft {
	 flex: 0.5 1;
	 height: 75vh;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
}
 .gender .genderCotainer .genderContainerLeft .logo {
	 object-fit: contain;
	 width: 40%;
}
 .gender .genderCotainer .genderContainerLeft .cherriImage {
	 object-fit: contain;
	 width: 90%;
}
 .gender .genderCotainer .genderContainerRight {
	 height: 85vh;
	 background-color: white;
	 width: 35%;
	 padding: 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 grid-gap: 3rem;
	 gap: 3rem;
	 border-radius: 16px;
}
 .gender .genderCotainer .genderContainerRight h1 {
	 font-size: 2em;
	 font-weight: 600;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow {
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: space-evenly;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow .genderContainerRightBox {
	 width: 8rem;
	 height: 8rem;
	 border: none;
	 outline-width: 0;
	 border-radius: 11px;
	 box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
	 cursor: pointer;
	 position: relative;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow .genderContainerRightBox svg {
	 position: absolute;
	 top: 10px;
	 right: 10px;
	 font-size: medium;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow .genderContainerRightBox p {
	 font-size: 1.2rem;
	 color: #ac11fd;
	 font-weight: 600;
	 text-align: center;
}
 .gender .genderCotainer .genderContainerRight button {
	 margin-top: 3rem;
	 width: -moz-fit-content;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 10px;
	 gap: 10px;
}
 
@media screen and (max-width: 768px) {
  .gender .genderCotainer .genderContainerLeft {
		display: none;
	}

	.gender .genderCotainer .genderContainerRight {
		width: 80%;
	}
	.gender .genderCotainer .genderContainerRight h1 {
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 400px) {

}

.navbar {
  width: 100%;
  height: 95px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  z-index: 999 !important;
  position: sticky;
}

.navbar.active {
  -webkit-backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background: hsla(0, 0%, 100%, 0.5);
  box-shadow: 0 25px 26px -1px rgb(0 0 0 / 10%);
}

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.navbar-container > ul {
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0px;
}

.navbar-logo {
  text-decoration: none;
  color: #3f51b5;
  justify-self: start;
  margin-left: 2.6rem;
  cursor: pointer;
}

.navbar-logo > img {
  width: auto;
  height: 75px;
}

.nav-menu {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, auto);
  grid-gap: 0px 10px;
  list-style: none;
  text-align: center;
  /* min-width: 70vw; */
  justify-content: end;
  margin: 0 2rem 0 0;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.ReactSelect {
  width: 8rem;
}

.nav-links {
  color: #000;
  text-decoration: none;
  padding: 0rem 1rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  transition: all 50ms ease-in-out;
}

.nav-links:hover {
  color: rgb(220, 206, 206) !important;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar__button {
  background-color: transparent;
  border: 2px solid green;
  padding: 3px 20px;
  cursor: pointer;
  border-radius: 9px;
  margin: 0 0.25rem;
}

.navbar__button > button {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
}

.navbar__buttons {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}

.navbarUser__details {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin-right: 1rem;
}

.navbarUser__details > p {
  color: #000;
  text-decoration: none;
  letter-spacing: 0.01rem;
  font-weight: 500;
}

.navbarUser__details > button > .MuiSvgIcon-root {
  color: gray;
  cursor: pointer;
  /* padding: 0rem 0 0 1rem; */
}

.notification__popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.popOverContainer {
  padding-top: 3rem;
  position: absolute;
  top: 1rem;
  right: -6rem;
  overflow-y: scroll;
}

.coinsEarned {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px solid gray;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-logo {
    text-decoration: none;
    color: #3f51b5;
    justify-self: start;
    margin-left: 10px;
    cursor: pointer;
    transform: translate(50%, 50%);
  }

  .navbar-logo > img {
    width: auto;
    height: 40px;
    margin-top: 0;
  }

  .nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    top:95px;

    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow-x: hidden;
  }

  .nav-menu.active {
    border-top: 1px solid #fce4ec;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
  }

  .nav-links {
    margin: auto;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-item:hover {
    width: 60vw;
    border-radius: 20px;
    color: #242424;
    border-radius: 0;
  }

  .nav-links:hover {
    color: #242424;
    font-weight: 700;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    /* transform: translate(15%, 25%); */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    border-radius: 4px;
    width: 60vw;
    text-decoration: none;
    font-size: 1.2rem;
    background-color: transparent;
    color: white;
    padding: 14px 14px;
    border: 1px solid lightgrey;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #d8d7d7;
    color: #242424;
    transition: 250ms;
    font-weight: 700;
  }
  .navbar__button {
    display: none;
  }
}

.popUpContainer {
  border-radius: 9px;
  border: 1px solid rgb(235, 235, 235);
  background-color: #fcfcfc;
  z-index: 2;
  width: 30rem;
  min-height: 70vh;
  overflow-y: scroll;
  pointer-events: none;
}

.popUpContainer::before {
  content: '';
  position: absolute;
  display: inline-block;
  width: 14px;
  height: 14px;
  border-width: 1px 0px 0px 1px;
  border-top-style: solid;
  border-left-style: solid;
  border-top-color: rgb(235, 235, 235);
  border-left-color: rgb(235, 235, 235);
  border-image: initial;
  transform: rotate(45deg) translateX(-50%);
  top: 2.8rem;
  left: 75%;
  border-right-style: initial;
  border-right-color: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  background-color: #fcfcfc;
}

.popUpContainer__box {
  color: rgb(21, 21, 21);
  font-size: 1rem;
  padding: 1.5rem 10px;
  overflow-y: scroll;
}

.notification__row {
  display: flex;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.notification__row:last-child {
  border-bottom: none;
}

.notification__row > img {
  width: 4rem;
  height: 4rem;
  border-radius: 9px;
}

.notification__rowItem {
  margin-left: 1rem;
}

.notification__rowItem > h2 {
  font-size: 0.9rem;
}

.notification__rowItem > p {
  font-size: 0.8rem;
  margin: 0.2rem 0;
}

.notification__rowItem > span {
  font-size: 0.8rem;
}

.location {
  background: url(/static/media/SignInBG.83fcd96c.png);
  background-repeat: no-repeat;
  height: 100vh;
}
.location .locationCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5%;
  gap: 5%;
}
.location .locationCotainer .locationContainerLeft {
  flex: 0.45 1;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.location .locationCotainer .locationContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.location .locationCotainer .locationContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.location .locationCotainer .locationContainerRight {
  background-color: white;
  width: 35%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.location .locationCotainer .locationContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.location .locationCotainer .locationContainerRight p {
  font-size: 1rem;
  cursor: pointer;
}
.location .locationCotainer .locationContainerRight .autocomplete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  width: 75%;
  margin-top: 2rem;
}

.location
  .locationCotainer
  .locationContainerRight
  .autocomplete
  > .ReactSelect {
  width: 100%;
}

.location .locationCotainer .locationContainerRight .submit__button {
  margin-top: 2rem;
}
.location .locationCotainer .locationContainerRight .submit__button button {
  width: 100% !important;
  margin-bottom: 5px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.location
  .locationCotainer
  .locationContainerRight
  .locationContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.location .locationCotainer .locationContainerRight .marginer {
  border-bottom: 1px solid gray;
  width: 100%;
}
.location .locationCotainer .locationContainerRight p {
  font-size: 0.8rem;
}
.MuiAutocomplete-root button {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .location .locationCotainer .locationContainerLeft {
    display: none;
  }

  .location .locationCotainer .locationContainerRight {
    width: 80%;
  }
  .location .locationCotainer .locationContainerRight {
    font-size: 1.4rem;
  }
}

.otp {
  background: url(/static/media/SignInBG.83fcd96c.png);
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}
.otp .otpCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5%;
  gap: 5%;
}
.otp .otpCotainer .otpContainerLeft {
  flex: 0.45 1;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.otp .otpCotainer .otpContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.otp .otpCotainer .otpContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.otp .otpCotainer .otpContainerRight {
  background-color: white;
  width: 35%;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  position: relative;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.otp .otpCotainer .otpContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.otp .otpCotainer .otpContainerRight p {
  font-size: 1rem;
}
.otp .otpCotainer .otpContainerRight button {
  margin-top: 2rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom .marginer {
  border-bottom: 1px solid gray;
  width: 100%;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom p {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .otp .otpCotainer .otpContainerLeft {
    display: none;
  }

  .otp .otpCotainer .otpContainerRight {
    width: 80%;
  }
  .otp .otpCotainer .otpContainerRight h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px) {
}

.otp {
  background: url(/static/media/SignInBG.83fcd96c.png);
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}
.otp .otpCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5%;
  gap: 5%;
}
.otp .otpCotainer .otpContainerLeft {
  flex: 0.45 1;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.otp .otpCotainer .otpContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.otp .otpCotainer .otpContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.otp .otpCotainer .otpContainerRight {
  background-color: white;
  width: 35%;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  position: relative;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.otp .otpCotainer .otpContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.otp .otpCotainer .otpContainerRight p {
  font-size: 1rem;
}
.otp .otpCotainer .otpContainerRight button {
  margin-top: 2rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom .marginer {
  border-bottom: 1px solid gray;
  width: 100%;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom p {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .otp .otpCotainer .otpContainerLeft {
    display: none;
  }

  .otp .otpCotainer .otpContainerRight {
    width: 80%;
  }
  .otp .otpCotainer .otpContainerRight h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px) {
}

.img-div-section {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.statistics {
  width: 100%;
  border-top: 1px solid #e8eaf6;
  background-color: #760a98;
  padding: 30px 0px;
}

.statistics .display-slider {
  display: none;
}
.statistics .bottom-image-section {
  display: flex;
  justify-content: center;
}
.statistics .bottom-image-section .img-div-section {
  margin: auto 5rem;
}

.statistics .bottom-image-section .img-div-section .image-section {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  margin: auto;
  border: 4px solid #1565c0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statistics .image-section .img-section img {
  height: 33px;
}
.statistics .bottom-image-section .img-div-section .image-section .img-section {
  display: flex;
  justify-content: center;
}
.statistics .bottom-image-section .des-section {
  font-style: normal;
}
.statistics .bottom-image-section .des-section .title {
  font-weight: 600;
  font-size: 23.3265px;
  line-height: 33px;
  letter-spacing: 0.03em;
}
.statistics .bottom-image-section .des-section .sub-title {
  font-weight: 400;
  font-size: 15.1748px;
  line-height: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .top-section {
    margin-bottom: 3rem;
    flex-direction: column-reverse;
  }
  .top-section .left-section {
    width: 75%;
    text-align: center;
  }
  .top-section .left-section .title {
    text-align: center;
  }
  .top-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .top-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .top-section .left-section .sub-title {
    text-align: center;
  }
  .top-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .top-section .left-section .right-drop-section .end-title {
    background-color: #a629f6;
    left: auto;
  }

  .top-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .top-section .right-section {
    width: 95%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .top-section {
    flex-direction: column-reverse;
    margin-bottom: 3rem;
  }
  .top-section .left-section {
    width: 95%;
  }
  .top-section .left-section .title {
    text-align: center;
    font-size: 35px;
    line-height: 47px;
  }
  .top-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .top-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .top-section .left-section .sub-title {
    text-align: center;
    font-size: 11.64px;
    line-height: 15px;
    margin-top: 12px;
  }
  .top-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }

  .top-section .left-section .right-drop-section .set-bottom button {
    padding: 8.5px 30px;
  }

  .top-section .left-section .right-drop-section .set-bottom .button-section {
    display: flex;
    padding: 20px 13px;
  }

  .top-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .top-section .right-section {
    width: 95%;
  }
  .top-section .right-section .img-div {
    max-width: 250px;
    min-width: 100px;
  }
  .statistics {
    text-align: center;
  }
  .statistics .display-card {
    display: none;
  }
  .statistics .display-slider {
    display: block !important;
  }
  .statistics .display-slider .image-section {
    width: 70px;
    height: 70px;
    background: #fff;
    border-radius: 50%;
    margin: auto;
    border: 5px solid #c77cfa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .statistics .display-slider .image-section .img-section {
    display: flex;
    justify-content: center;
  }
  .statistics .display-slider .des-section .title {
    font-size: 20px;
  }
  .statistics .display-slider .des-section .sub-title {
    font-size: 15.4017px;
  }
}

.App .display-main-section {
  display: block;
}
.App .none-main-section {
  display: none;
}
.App .display-header {
  display: block;
}
.App .menu-mobile {
  padding: 30px 20px;
}
.App .menu-mobile .closeIcon {
  text-align: end;
}
.App .menu-mobile .closeIcon button > svg {
  color: #fff;
  cursor: pointer;
}
.App .menu-mobile .menu-item {
  text-align: center;
  font-size: 1.5rem;
  padding: 30px 0;
}
.App .menu-mobile .menu-item a {
  text-decoration: none;
  color: #fff;
}
.App .menu-mobile .menu-item:hover {
  color: #dedede;
}
.App .none-header {
  display: none;
}
.App .full-div {
  overflow-x: hidden;
  overflow: hidden;
}
.App .full-div .header-component {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/heroBg.7c61b991.png);
  background: #760a98;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.App .full-div .header-component .set-bg-div {
  display: none;
}

.color-2DB4FF {
  color: #2db4ff;
}



.bg-color-2DB4FF {
  background-color: #2db4ff;
}

.border-2DB4FF {
  border: 2px solid #2db4ff;
}

.color-AE46F5 {
  color: #ae46f5;
}

.bg-color-AE46F5 {
  background-color: #ae46f5;
}

.border-AE46F5 {
  border: 2px solid #ae46f5;
}

.color-FFFFFF {
  color: #ffffff;
}

.bg-color-FFFFFF {
  background-color: #ffffff;
}

.border-FFFFFF {
  border: 2px solid #ffffff;
}

.color-black {
  color: #000;
}

.bg-color-black {
  background-color: #000;
}

.border-black {
  border: 2px solid #000;
}

.color-EBEBEB {
  color: #ebebeb;
}

.bg-color-EBEBEB {
  background-color: #ebebeb;
}

.border-EBEBEB {
  border: 2px solid #ebebeb;
}

.color-F8F8F8 {
  color: #f8f8f8;
}

.bg-color-F8F8F8 {
  background-color: #f8f8f8;
}

.border-F8F8F8 {
  border: 2px solid #f8f8f8;
}

.color-A212FF {
  color: #a212ff;
}

.bg-color-A212FF {
  background-color: #a212ff;
}

.border-A212FF {
  border: 2px solid #a212ff;
}

.color-E7E2F8 {
  color: #e7e2f8;
}

.bg-color-E7E2F8 {
  background-color: #e7e2f8;
}

.border-E7E2F8 {
  border: 2px solid #e7e2f8;
}

.color-A025ED {
  color: #a025ed;
}

.bg-color-A025ED {
  background-color: #a025ed;
}

.border-A025ED {
  border: 2px solid #a025ed;
}

.color-1AC4FA {
  color: #1ac4fa;
}

.bg-color-1AC4FA {
  background-color: #1ac4fa;
}

.border-1AC4FA {
  border: 2px solid #1ac4fa;
}

.color-E122E5 {
  color: #e122e5;
}

.bg-color-E122E5 {
  background-color: #e122e5;
}

.border-E122E5 {
  border: 2px solid #e122e5;
}

.color-CC7DFF {
  color: #cc7dff;
}

.bg-color-CC7DFF {
  background-color: #cc7dff;
}

.border-CC7DFF {
  border: 2px solid #cc7dff;
}

.color-CA7CFF {
  color: #ca7cff;
}

.bg-color-CA7CFF {
  background-color: #ca7cff;
}

.border-CA7CFF {
  border: 2px solid #ca7cff;
}

.color-FFB504 {
  color: #ffb504;
}

.bg-color-FFB504 {
  background-color: #ffb504;
}

.border-FFB504 {
  border: 2px solid #ffb504;
}

.color-FFEB3B {
  color: #FFEB3B;
}

.bg-color-ffc727 {
  background-color: #ffc727;
}

.border-ffc727 {
  border: 2px solid #ffc727;
}

.color-8d00ee {
  color: #8d00ee;
}

.bg-color-8d00ee {
  background-color: #8d00ee;
}

.border-8d00ee {
  border: 2px solid #8d00ee;
}

.color-944cc3 {
  color: #944cc3;
}

.bg-color-944cc3 {
  background-color: #944cc3;
}

.border-944cc3 {
  border: 2px solid #944cc3;
}

.span-text-color {
  color: #8d00ee;
}

button {
  outline: none;
  border: 0;
  cursor: pointer;
}
button:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 10px 0px;
}

.alice-carousel .alice-carousel__dots {
  margin: 5px 0px 16px 0px;
  display: flex;
  justify-content: center;
}
.alice-carousel .alice-carousel__prev-btn {
  position: absolute;
  left: -25px;
  top: 35%;
  width: auto;
  font-size: 2rem;
}
.alice-carousel .alice-carousel__next-btn {
  position: absolute;
  right: -25px;
  top: 35%;
  width: auto;
  font-size: 2rem;
}



.common-header {
  position: sticky;
  top: 0;
  background-color: #ca7cff;
}
.common-header .header-none {
  display: none;
}
.common-header .header-block {
  display: block;
}

.common-footer {
  background-image: linear-gradient(
    to top,
    #a212ff,
    #a212ff,
    #a212ff,
    #a212ff,
    #a212ff,
    #a624ff,
    #a930ff,
    #ad3aff,
    #b54dff,
    #bc5eff,
    #c36dff,
    #ca7cff
  );
}

.text-center {
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .span-text-color {
    color: #fa441a;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .span-text-color {
    color: #fa441a;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .span-text-color {
    color: #fa441a;
  }
}

.bottom-slider-wrapper {
  width: 100%;
  padding-top: 4rem;
  background-color: #6a1b9a;
}
.bottom-slider-wrapper .left-section .span-text-color {
  color: #ffc727;
}
.bottom-slider {
  margin-bottom: 100px;
  max-width: 80%;
  margin: auto;
  margin-top: 5rem;
}
.getthe {
  color: #212121;
}
.bottom-slider .top-section {
  text-align: center;
  margin-bottom: 110px;
}
.bottom-slider .top-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 54px;
  margin-bottom: 36px;
}
.bottom-slider .top-section .title .color-AE46F5-FA441A {
  color: #ae46f5;
}
.bottom-slider .top-section .button-section {
  max-width: 250px;
  margin: auto;
  position: relative;
}
.bottom-slider .top-section .button-section .button {
  background: linear-gradient(180deg, #ae46f5 0%, rgba(145, 2, 238, 0.21) 100%);
  border-radius: 20px;
}
.bottom-slider .top-section .button-section .button .title {
  font-style: normal;
  font-weight: bold;
  font-size: 16.03px;
  line-height: 12px;
  color: #fff;
  padding: 15px 15px 20px 40px;
  text-align: center;
}
.bottom-slider .top-section .button-section .button .button-img {
  position: absolute;
  left: 20px;
}
.bottom-slider .top-section .link-button {
  max-width: 400px;
  margin: auto;
}
.bottom-slider .top-section .link-button .link {
  display: flex;
  justify-content: space-between;
}
.bottom-slider .top-section .link-button .link .mac-button {
  width: 170px;
}
.bottom-slider .top-section .link-button .link .mac-button img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .top-section .link-button .link .windows-button {
  width: 170px;
}
.bottom-slider .top-section .link-button .link .windows-button img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .link-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-slider .link-info .left-section {
  width: 50%;
}
.bottom-slider .link-info .left-section .title {
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 73px;
  margin-bottom: 2rem;
}
.bottom-slider .link-info .left-section .sub-title {
  color: white;
  font-style: normal;
  font-weight: 300;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  line-height: 61px;
}
.bottom-slider .link-info .left-section .link-button {
  display: flex;
}
.bottom-slider .link-info .left-section .link-button a {
  text-decoration: none !important;
  color: inherit;
}
.bottom-slider .link-info .left-section .link-button .button-section {
  margin: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bottom-slider .link-info .left-section .link-button .button-section .icon-div {
  width: 30px;
  display: flex;
  justify-content: center;
}
.bottom-slider
  .link-info
  .left-section
  .link-button
  .button-section
  .icon-div
  img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .link-info .left-section .link-button .button-section .content {
  margin-left: 10px;
  line-height: 1;
  font-size: 13px;
}
.bottom-slider
  .link-info
  .left-section
  .link-button
  .button-section
  .content
  .con-sub-title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}
.bottom-slider .link-info .right-section {
  width: 50%;
}
.bottom-slider .link-info .right-section .image-section {
  max-width: 500px;
  margin: auto;
}
.bottom-slider .link-info .right-section .image-section img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .main-slider-section {
  position: relative;
}
.bottom-slider .mobile-img-section {
  position: absolute;
  top: 1px;
  z-index: 2;
  width: 200px;
  display: flex;
  justify-content: center;
  transform: translate(-50%);
  left: 50%;
}
.bottom-slider .mobile-img-section img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .main {
  min-height: 200px;
  height: 400px;
}
.bottom-slider .main .img-section {
  position: absolute;
  top: 7px;
  z-index: 1;
  width: 170px;
  margin: auto;
  height: 355px;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translate(-50%);
}
.bottom-slider .main .img-section img {
  width: 100%;
  object-fit: cover;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bottom-slider .top-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .bottom-slider .top-section .button-section .button {
    background: linear-gradient(
      180deg,
      #fa441a 0%,
      rgba(250, 68, 26, 0.4) 100%
    );
  }
  .bottom-slider .link-info {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
  .bottom-slider .link-info .left-section {
    width: 90%;
    margin-top: 20px;
  }
  .bottom-slider .link-info .left-section .title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .bottom-slider .link-info .left-section .sub-title {
    font-size: 22px;
    line-height: 25px;
  }
  .bottom-slider .link-info .left-section .link-button {
    justify-content: center;
    width: 240px;
    margin: auto;
    flex-direction: column;
  }
  .bottom-slider .link-info .right-section {
    width: 95%;
  }
  .bottom-slider .link-info .right-section .image-section {
    max-width: 250px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .bottom-slider .top-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .bottom-slider .top-section .button-section .button {
    background: linear-gradient(
      180deg,
      #fa441a 0%,
      rgba(250, 68, 26, 0.4) 100%
    );
  }
  .bottom-slider .link-info {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
  .bottom-slider .link-info .left-section {
    width: 90%;
    margin-top: 20px;
  }
  .bottom-slider .link-info .left-section .title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .bottom-slider .link-info .left-section .sub-title {
    font-size: 22px;
    line-height: 25px;
  }
  .bottom-slider .link-info .left-section .link-button {
    justify-content: center;
    width: 240px;
    margin: auto;
    flex-direction: column;
  }
  .bottom-slider .link-info .right-section {
    width: 95%;
  }
  .bottom-slider .link-info .right-section .image-section {
    max-width: 250px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .bottom-slider {
    padding-bottom: 50px;
  }
  .bottom-slider .top-section {
    margin-bottom: 50px;
  }
  .bottom-slider .top-section .title {
    font-size: 27px;
    line-height: 54px;
    margin-bottom: 20px;
  }
  .bottom-slider .top-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .bottom-slider .top-section .button-section .button {
    background: linear-gradient(
      180deg,
      #fa441a 0%,
      rgba(250, 68, 26, 0.4) 100%
    );
  }
  .bottom-slider .top-section .link-button {
    max-width: 210px;
  }
  .bottom-slider .top-section .link-button .link .mac-button {
    max-width: 95px;
  }
  .bottom-slider .top-section .link-button .link .windows-button {
    max-width: 95px;
  }
  .bottom-slider .link-info {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
  .bottom-slider .link-info .left-section {
    width: 90%;
    margin-top: 20px;
  }
  .bottom-slider .link-info .left-section .title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .bottom-slider .link-info .left-section .sub-title {
    font-size: 22px;
    line-height: 25px;
  }
  .bottom-slider .link-info .left-section .link-button {
    justify-content: center;
    width: 240px;
    margin: auto;
    flex-direction: column;
  }
  .bottom-slider .link-info .right-section {
    width: 95%;
  }
  .bottom-slider .link-info .right-section .image-section {
    max-width: 250px;
  }
}

.education-section {
  width: 100%;
  background-color: #6a1b9a;
  color: white;
  margin: auto;
  padding-bottom: 50px;
  padding-top: 40px;
}

.education-section .span-text-color {
  color: #ffc727;
}
.education-section .education-content-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.education-section .education-content-section .left-section {
  width: 35%;
  margin: auto;
}
.education-section .education-content-section .left-section img {
  object-fit: cover;
  width: 100%;
}
.education-section .education-content-section .slider-section {
  display: none;
}
.education-section .education-content-section .right-section {
  width: 40%;
  margin: auto;
}
.education-section .education-content-section .right-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 76px;
}
.education-section
  .education-content-section
  .right-section
  .title
  .color-AE46F5-FA441A {
  color: #ae46f5;
}
.education-section .education-content-section .right-section .des {
  font-style: normal;
  font-weight: 400;
  font-size: 18.64px;
  line-height: 36px;
}
.education-section .education-content-section .right-section .button-section {
  margin-top: 20px;
}
.education-section
  .education-content-section
  .right-section
  .button-section
  .bg-color-FFC727-F96763 {
  background-color: #ffc727;
  color: #fff;
}
.education-section
  .education-content-section
  .right-section
  .button-section
  .bg-color-8D00EE-F96763 {
  background-color: #ffeb3b;
  font-size: 1.2rem;
  color: black !important;
}

.button-readmore {
  font-style: normal;
  text-decoration: none;
  font-weight: 400;
  font-size: 26px;
  line-height: 37px;
  border-radius: 25px;
  padding: 15px 45px;
  transition: all 60ms ease-in;
}

.button-readmore:hover {
  background: #f8e005 !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .education-section .education-content-section {
    display: block;
  }
  .education-section .education-content-section .left-section {
    width: 90%;
  }
  .education-section .education-content-section .slider-section {
    display: none;
  }
  .education-section .education-content-section .right-section {
    width: 90%;
    text-align: center;
  }
  .education-section
    .education-content-section
    .right-section
    .title
    .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .education-section .education-content-section .right-section .button-section {
    display: flex;
    justify-content: center;
  }
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-FFC727-F96763,
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-8D00EE-F96763 {
    background-color: #f96763;
    color: #fff;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .education-section .education-content-section {
    display: block;
  }
  .education-section .education-content-section .left-section {
    width: 90%;
  }
  .education-section .education-content-section .slider-section {
    display: none;
  }
  .education-section .education-content-section .right-section {
    width: 90%;
    text-align: center;
  }
  .education-section
    .education-content-section
    .right-section
    .title
    .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .education-section .education-content-section .right-section .button-section {
    display: flex;
    justify-content: center;
  }
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-FFC727-F96763,
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-8D00EE-F96763 {
    background-color: #f96763;
    color: #fff;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .education-section .education-content-section {
    display: block;
  }
  .education-section .education-content-section .left-section {
    width: 90%;
    display: none;
  }
  .education-section .education-content-section .slider-section {
    display: block;
  }
  .education-section
    .education-content-section
    .slider-section
    .alice-carousel__stage-item {
    width: 100% !important;
  }
  .education-section .education-content-section .slider-section .img-section {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .education-section
    .education-content-section
    .slider-section
    .img-section
    img {
    width: 100%;
    object-fit: cover;
  }
  .education-section .education-content-section .right-section {
    width: 90%;
    text-align: center;
  }
  .education-section .education-content-section .right-section .title {
    font-size: 27px;
    line-height: 44px;
  }
  .education-section
    .education-content-section
    .right-section
    .title
    .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .education-section .education-content-section .right-section .des {
    font-size: 11px;
    line-height: 16px;
  }
  .education-section .education-content-section .right-section .button-section {
    display: flex;
    justify-content: center;
    font-size: 11px;
    line-height: 16px;
  }
  .button-readmore {
    padding: 1px 18px;
  }
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-FFC727-F96763,
  .education-section
    .education-content-section
    .right-section
    .button-section
    .bg-color-8D00EE-F96763 {
    background-color: #f96763;
    color: #fff;
  }
}

.footer-section {
  margin: 90%;
  margin: auto;
  background-color: #6A1B9A;
  
}
.footer-section .footer-main-section {
  display: flex;
  justify-content: space-between;
}
.footer-section .footer-main-section .center-section {
  text-align: center;
  margin: auto;
}
.img-div > img {
  width: 6rem;
}
.footer-section .footer-main-section .center-section .des-section {
  width: 70%;
  margin: 25px auto 10px auto;
  font-style: normal;
  font-weight: 400;
  font-size: 15.6417px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
}
.footer-section .footer-main-section .center-section .sub-des-section {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 54px;
  text-align: center;
}
.footer-section .footer-main-section .center-section .link-button-section {
  margin-top: 10px;
  margin-bottom: 10px;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button {
  display: flex;
  justify-content: center;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  a {
  text-decoration: none;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section {
  margin: 5px;
  padding: 10px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  cursor: pointer;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  img {
  width: 60%;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .icon-div {
  width: 40px;
  display: flex;
  justify-content: center;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .content {
  margin-left: 10px;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .content
  .con-sub-title {
  font-weight: 600;
  font-size: 0.8rem;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .content
  .con-sub-2-title {
  font-size: 0.8rem;
}
.footer-section .footer-main-section .f1 {
  margin: auto;
}
.footer-section .footer-main-section .f1 img {
  width: 100%;
}
.footer-section .footer-main-section .f3 {
  margin: auto;
}
.footer-section .footer-main-section .f3 img {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 150px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 200px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 150px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 200px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 2%;
    left: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .center-section .img-div img {
    width: 6rem;
  }
  .footer-section .footer-main-section .center-section .des-section {
    font-size: 9px;
    line-height: 15px;
  }
  .footer-section .footer-main-section .center-section .sub-des-section {
    font-size: 14px;
    line-height: 20px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}
@media (min-width: 100px) and (max-width: 480px) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 2%;
    left: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .center-section .img-div img {
    width: 6rem;
  }
  .footer-section .footer-main-section .center-section .des-section {
    font-size: 9px;
    line-height: 15px;
  }
  .footer-section .footer-main-section .center-section .sub-des-section {
    font-size: 14px;
    line-height: 20px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}

.footer-link-section {
  position: relative;
  height: 100%;
  background-color: #6A1B9A;
}
.footer-link-section .image-section {
  display: flex;
}
.footer-link-section .image-section img {
  width: 100%;
}
.footer-link-section .footer-link-content {
  position: absolute;
  top: 0;
  width: 100%;
}
.footer-link-section .footer-link-content .link-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-link-section .footer-link-content .link-section .link-div {
  margin: auto 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.footer-link-section .footer-link-content .link-section .dot {
  height: 8px;
  width: 8px;
  background-color: #fff;
  border-radius: 50%;
}
.footer-link-section .footer-link-content .social-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.footer-link-section .footer-link-content .social-section .link-div {
  margin: auto 15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.footer-link-section .footer-link-content .social-section .link-div svg {
  fill: #fff;
}
.footer-link-section .footer-link-content .social-section .link-div:hover {
  background-color: #fff !important;
}
.footer-link-section .footer-link-content .social-section .link-div:hover svg {
  fill: blueviolet !important;
  stroke: blueviolet !important;
}
.footer-link-section .footer-link-content .social-section .insta-link {
  margin: auto 15px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.footer-link-section .footer-link-content .social-section .insta-link:hover {
  background-color: #fff !important;
}
.footer-link-section .footer-link-content .social-section .insta-link:hover svg {
  color: blueviolet !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .link-div {
    font-size: 10px;
    line-height: 12px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}
@media (min-width: 100px) and (max-width: 480px) {
  .footer-link-section .footer-link-content .link-section {
    flex-direction: column;
    padding-top: 10px;
 }
  .footer-link-section .footer-link-content .link-section .link-div {
    font-size: 10px;
    line-height: 12px;
 }
  .footer-link-section .footer-link-content .link-section .dot {
    display: none;
 }
  .footer-link-section .footer-link-content .social-section .link-div {
    width: 24px;
    height: 24px;
 }
  .footer-link-section .footer-link-content .social-section .link-div svg {
    width: 11px;
 }
  .footer-link-section .footer-link-content .social-section .insta-link {
    width: 24px;
    height: 24px;
 }
  .footer-link-section .footer-link-content .social-section .insta-link svg {
    width: 11px;
 }
  .footer-link-section .footer-link-content {
    position: unset;
 }
  .footer-link-section .footer-link-content .image-section img {
    width: 100%;
 }
}

.newsCarouselContainer {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  background-color: #FCE4EC;
  justify-content: center;
  padding-bottom: 50px;
}

.carouselTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 27px;
  text-align: center;
}

.carouselTitle span {
  color: #8d00ef;
}
.aliceCarouselWrapper {
  width: 80%;
  margin: 0 auto;
}

@media screen and (min-width: 600px) {
  .newsCarouselContainer {
    padding-top: 110px;
    padding-bottom: 90px;
  }
  .carouselTitle {
    font-size: 2.2rem;
    text-align: center;
  }
}
@media screen and (min-width: 1300px) {
  .aliceCarouselWrapper {
    width: 95%;
  }
}

.newsCard {
  width: auto;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  transition: 130ms all ease-in;
  padding: 18px 30px;
}
.newsCard:hover {
  transform: scale(1.02);
}

.newsBody {
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: 15px;
  border: 1px solid black;
}
.newsBody img {
  width: 100%;
  height: 230px;
}

.newsLogo {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  background-color: #6A1B9A;
  padding: 30px;
  border: 1px solid black;
  border-top: none;
}

.newsLogo img {
  border-radius: 2px;
  height: 50px;
}
@media screen and (min-width: 400px) {
  .newsCard {
    padding: 18px 40px;
  }
}

@media screen and (min-width: 440px) {
  .newsCard {
    padding: 18px 50px;
  }
}

@media screen and (min-width: 470px) {
  .newsCard {
    padding: 18px 60px;
  }
}

@media screen and (min-width: 505px) {
  .newsCard {
    padding: 18px 75px;
  }
}

@media screen and (min-width: 530px) {
  .newsCard {
    padding: 18px 85px;
  }
}
@media screen and (min-width: 568px) {
  .newsCard {
    padding: 18px 18px;
  }
}
@media screen and (min-width: 900px) {
  .newsCard {
    padding: 18px 18px;
  }
  .newsBody img {
    height: 250px;
  }
}

@media screen and (min-width: 1300px) {
  .newsCard {
    padding: 20px 20px;
  }
  .newsBody img {
    height: 320px;
  }
}

.learning-potential-section {
  background-color: #6a1b9a;
  padding-top: 20px;
  padding-bottom: 20px;
}

.learning-potential-section .learning-section {
  max-width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.learning-potential-section .learning-section .left-section {
  width: 50%;
}
.learning-potential-section .learning-section .left-section .ul-div {
  display: flex;
  margin-top: 15px;
  justify-content: flex-start;
  align-items: center;
  color: white;
}
.learning-potential-section .learning-section .left-section .ul-div .img-div {
  padding-top: 5px;
}
.learning-potential-section .learning-section .left-section .ul-div img {
  width: 10px;
  height: 10px;
}
.learning-potential-section .learning-section .left-section .ul-div .li-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18.64px;
  line-height: 26px;
  margin-left: 15px;
  text-align: left;
}
.learning-potential-section .learning-section .left-section .title {
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 2.2rem;
  line-height: 65px;
}
.learning-potential-section .learning-section .left-section .title .color-p-r {
  color: #ae46f5;
}
.learning-potential-section .learning-section .left-section .sub-title {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  color: white;

  font-size: 18.64px;
  line-height: 36px;
}
.learning-potential-section .learning-section .left-section .button-section {
  margin-top: 2rem;
}
.learning-potential-section .learning-section .left-section .button-section a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  border-radius: 22px;
  width: -moz-fit-content;
  width: fit-content;
  color: black;
  text-decoration: none;
  background: #FFEB3B;
  padding: 8px 28px;
  transition: 130ms all ease-in;
}
.learning-potential-section
  .learning-section
  .left-section
  .button-section
  a:hover {
    background: #f8e005;

}

.learning-potential-section
  .learning-section
  .left-section
  .button-section
  a
  .svg-button {
  fill: #fff;
  margin-left: 3px;

  border-radius: 50%;
}
.learning-potential-section .learning-section .right-section {
  width: 45%;
  margin: 5%;
}
.learning-potential-section .learning-section .right-section img {
  object-fit: cover;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .learning-potential-section .learning-section {
    flex-direction: column-reverse;
  }
  .learning-potential-section .learning-section .left-section {
    width: 90%;
    text-align: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .title
    .color-p-r {
    color: #fa441a;
  }
  .learning-potential-section .learning-section .left-section .button-section {
    display: flex;
    justify-content: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button {
    background-color: #fa441a;
    color: #fff;
    position: relative;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button
    .svg-button {
    margin-left: 3px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -25px;
    font-size: 55px;
  }
  .learning-potential-section .learning-section .right-section {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .learning-potential-section .learning-section {
    flex-direction: column-reverse;
  }
  .learning-potential-section .learning-section .left-section {
    width: 90%;
    text-align: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .title
    .color-p-r {
    color: #fa441a;
  }
  .learning-potential-section .learning-section .left-section .button-section {
    display: flex;
    justify-content: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button {
    background-color: #fa441a;
    color: #fff;
    position: relative;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button
    .svg-button {
    margin-left: 3px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -25px;
    font-size: 55px;
  }
  .learning-potential-section .learning-section .right-section {
    width: 90%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .learning-potential-section .learning-section {
    flex-direction: column-reverse;
    padding: 3rem 0;
  }
  .learning-potential-section .learning-section .left-section {
    width: 90%;
    text-align: center;
  }
  .learning-potential-section .learning-section .left-section .title {
    font-size: 27px;
    line-height: 44px;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .title
    .color-p-r {
    color: #fa441a;
  }
  .learning-potential-section .learning-section .left-section .sub-title {
    font-size: 11.64px;
    line-height: 15px;
  }
  .learning-potential-section .learning-section .left-section .button-section {
    display: flex;
    justify-content: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button {
    background-color: #fa441a;
    color: #fff;
    position: relative;
    padding: 4.5px 30px 4.5px 15px;
    font-size: 21px;
    line-height: 30px;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button
    .svg-button {
    margin-left: 3px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -20px;
    font-size: 40px;
  }
  .learning-potential-section .learning-section .right-section {
    width: 90%;
  }
}

.main-section {
  max-width: 90%;
  margin: auto;
  z-index: 2;
  position: relative;
}

.main-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-section .left-section {
  width: 50%;
}
.main-section .left-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 3.3rem;
}
.main-section .left-section .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18.3844px;
  line-height: 29px;
}
.main-section .right-section {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
}
.main-section .right-section .img-div {
  max-width: 550px;
  min-width: 200px;
  position: relative;
}
.main-section .right-section .img-div .gif-div-img {
  width: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  top: 20%;
  left: 72.5%;
}
.main-section .right-section .img-div img {
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-section {
    margin-bottom: 3rem;
    flex-direction: column-reverse;
  }
  .main-section .left-section {
    width: 75%;
    text-align: center;
  }
  .main-section .left-section .title {
    text-align: center;
  }
  .main-section .left-section .title .color-1 {
    color: #ffff00;
  }
  .main-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .main-section .left-section .sub-title {
    text-align: center;
  }
  .main-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .main-section .left-section .right-drop-section .end-title {
    background-color: #a629f6;
    left: auto;
  }
  .main-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .main-section .right-section {
    width: 95%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .main-section {
    margin-bottom: 3rem;
    flex-direction: column-reverse;
  }
  .main-section .left-section {
    width: 75%;
    text-align: center;
  }
  .main-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .main-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .main-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .main-section .left-section .right-drop-section .end-title {
    background-color: #a213ff;
    left: auto;
  }
  .main-section .left-section .right-drop-section .set-bottom button {
    padding: 13.5px 40px;
  }
  .main-section .left-section .right-drop-section .set-bottom .button-section {
    display: flex;
  }
  .main-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .main-section .right-section {
    width: 95%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .main-section {
    padding-bottom: 3rem;
  }
  .main-section {
    flex-direction: column-reverse;
    margin-bottom: 3rem;
  }
  .main-section .left-section {
    display: flex;
    flex-direction: column;

    width: 95%;
  }
  .main-section .left-section .title {
    text-align: center;
    font-size: 35px;
    line-height: 47px;
  }
  .main-section .left-section .title .color-1 {
    color: #ffc727;
  }
  .main-section .left-section .title .color-5 {
    color: #fa441a;
  }
  .main-section .left-section .sub-title {
    text-align: center;
    font-size: 11.64px;
    line-height: 15px;
    margin-top: 12px;
  }
  .main-section .left-section .right-drop-section {
    display: flex;
    justify-content: center;
  }
  .main-section .left-section .right-drop-section .set-bottom button {
    padding: 8.5px 30px;
  }
  .main-section .left-section .right-drop-section .set-bottom .button-section {
    display: flex;
    padding: 20px 13px;
  }
  .main-section
    .left-section
    .right-drop-section
    .set-bottom
    .button-section
    .right-button {
    background-color: #fa441a;
    color: #fff !important;
  }
  .main-section .left-section .right-drop-section .end-title {
    background-color: #a41bfc;
    left: auto;
    font-size: 1.25rem;
  }
  .main-section .right-section {
    width: 95%;
  }
  .main-section .right-section .img-div {
    max-width: 250px;
    min-width: 100px;
  }
}
.main-download{
  margin: 0 auto;
  margin-top: 1rem;
}
.card {
  transition: all 120ms ease-in;
}

.span-text-color {
  color: #7B1FA2;
}
.card:hover {
  transform: scale(1.04);
}

.card-section {
  background-color:#FCE4EC;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
}
.card-section .slider {
  display: none !important;
}
.card-section .title {
  font-style: normal;
  font-weight: 600;
  width: 100%;
  font-size: 2.2rem;
  line-height: 62px;
  margin-bottom: 4rem;
  align-items: center;
  justify-content: center;

  text-align: center;
  flex-direction: column;
  display: flex;
}
.card-section .card-content {
  display: flex;
  justify-content: center;
}
.card-section .card-content .card {
  background-color: #E8EAF6;
  border-radius: 20px;
  border: 1px solid black;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  max-width: 400px;
  padding: 50px 30px;
  text-align: center;
}
.card-section .card-content .card .image-section {
  max-height: 185px;
}

.card-section .card-content .card .title-section {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 40px;
  margin-top: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  margin: auto;
}
.card-section .card-content .card .des-section {
  font-style: normal;
  font-weight: 400;
  font-size: 14.64px;
  line-height: 29px;
  margin-top: 7px;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .card-section .card-content .card {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .card-section .title .color-cherri {
    color: #fa441a;
  }
  .card-section .card-content {
    display: block;
  }
  .card-section .card-content .card {
    margin: 2rem auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .card-section .title .color-cherri {
    color: #fa441a;
  }
  .card-section .card-content {
    display: block;
  }
  .card-section .card-content .card {
    margin: 2rem auto;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .card-section .title {
    font-size: 27px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .card-section .title .color-cherri {
    color: #fa441a;
  }
  .card-section .display-slider-card {
    display: none;
  }
  .card-section .slider {
    display: flex !important;
  }
  .card-section .card-content .card {
    margin: 2rem 10px;
  }
  .card-section .card-content .card .title-section {
    font-size: 18.0467px;
    line-height: 29px;
  }
  .card-section .card-content .card .des-section {
    font-size: 10px;
    line-height: 18px;
  }
}

.ques {
  font-weight: 500;
}

.ques-ans-section .span-text-color {
  color: #ffc727;
}
.ques-ans-section {
  background-color: #6a1b9a;
  color: white !important;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.ques-ans-section .header-bg-section {
  display: flex;
  width: 80%;
  margin-left: auto;
}
.ques-ans-section .header-bg-section img {
  object-fit: cover;
  width: 100%;
}
.ques-ans-section .q-a-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ques-ans-section .q-a-section .left-section {
  width: 50%;
}
.ques-ans-section .q-a-section .left-section .image-section {
  display: flex;
  max-width: 560px;
  margin: auto;
}
.ques-ans-section .q-a-section .left-section .image-section img {
  width: 100%;
  object-fit: cover;
}
.ques-ans-section .q-a-section .right-section {
  width: 50%;
}
.ques-ans-section .q-a-section .right-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 54px;
  margin-bottom: 40px;
}
.ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
  color: #8d00ee;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content {
  max-width: 80%;
  margin-right: auto;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .ques-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 24px 26px;
  font-style: normal;
  font-size: 1rem;
  line-height: 32px;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .set-border {
  border: 0.924414px solid white;
  border-radius: 10px;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .active {
  background: #8d00ee;
  border: none;
  border-radius: 10px;
}


.ques-ans-section .q-a-section .right-section .ques-ans-content .cursor {
  cursor: pointer;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .cursor svg {
  stroke: white;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .displayAnswer {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 42px;
  margin-left: 2rem;
  margin-bottom: 20px;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .noneAnswer {
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ques-ans-section .q-a-section {
    flex-direction: column;
  }
  .ques-ans-section .q-a-section .left-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .left-section .image-section {
    width: 250px;
  }
  .ques-ans-section .q-a-section .right-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .right-section .title {
    text-align: center;
    margin-top: 20px;
  }
  .ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content {
    margin: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .ques-ans-section .q-a-section {
    flex-direction: column;
  }
  .ques-ans-section .q-a-section .left-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .left-section .image-section {
    width: 300px;
  }
  .ques-ans-section .q-a-section .right-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .right-section .title {
    text-align: center;
    margin-top: 20px;
  }
  .ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content {
    margin: auto;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .ques-ans-section .q-a-section {
    flex-direction: column;
  }
  .ques-ans-section .q-a-section .left-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .left-section .image-section {
    width: 200px;
  }
  .ques-ans-section .q-a-section .right-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .right-section .title {
    text-align: center;
    margin-top: 20px;
    font-size: 27px;
    line-height: 35px;
  }
  .ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content {
    margin: auto;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content .ques-div {
    font-size: 10px;
    line-height: 14px;
    padding: 6px 26px;
    align-items: center;
  }
  .ques-ans-section
    .q-a-section
    .right-section
    .ques-ans-content
    .displayAnswer {
    font-size: 10px;
    line-height: 14px;
  }
}

.scroller-section {
  background-color: #fce4ec;
  padding-top: 70px;
  padding-bottom: 50px;

}
.scroller-section .slider-top-section .title {
  background-color: #fc4747;
  padding: 4px 20px;
  border-radius: 35px;
  color: black;
  margin-top: 5px;
  margin-bottom: 10px;
}
.bg-color-F5FEFF {
  border: 1px solid black;
  background-color: #f5feff !important;
}
.scroller-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  text-align: center;
}
.scroller-section .title .span-text-color {
  color: #1a237e;
}
.scroller-section .title .color-p-r {
  color: #ae46f5;
}
.scroller-section .slider-content {
  width: 80%;
  margin: 45px auto;
}

.scroller-section .slider-content .slider-section {
  position: relative;
  font-style: normal;

  font-weight: 700;
  font-size: 37px;
  border-radius: 40px;
  padding-top: 20px;
  background-color: #e8eaf6 !important;
}
.scroller-section .slider-content .slider-section .slider-top-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-top-section
  .top-section
  .title {
  font-size: 1.7rem;
  color: white;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-top-section
  .top-section
  .sub-title {
  margin-top: 8px;
}

.scroller-section .slider-content .slider-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
}
.scroller-section .slider-content .slider-section .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
}
.scroller-section .slider-content .slider-section .slider-carousel {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-00BCD4 {
  background-color: #26a69a;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-A025ED-FF0000 {
  background-color: #a025ed;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-1AC4FA-A025ED {
  background-color: #1ac4fa;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .bg-color-E122E5-A4E522 {
  background-color: #e122e5;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .alice-carousel {
  margin-bottom: 30px;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .alice-carousel
  .alice-carousel__dots {
  display: none;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel-root {
  width: 80%;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .slide
  img {
  width: auto;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  img {
  width: auto;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .carousel-status {
  display: none;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .control-arrow {
  top: 50px;
  bottom: unset;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  top: 50%;
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .carousel
  .control-dots {
  display: none;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .alice-carousel__stage-item
  img {
  height: 178px;
  width: 100%;
}
.scroller-section .slider-content .slider-section .slider-carousel .main-div {
  margin: 10px;
  background-color: white;
  border-radius: 10px;
}
.bg-color-4DD0E1 {
  background-color: #00acc1;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .bottom-section {
  padding: 20px 0px;
  border-radius: 0px 0px 11px 11px;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .bottom-section
  .title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  font-weight: 400;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .img-section {
  padding: 15px 20px 15px 20px;
  border-radius: 11px 11px 0px 0px;
  border: 1px solid black;
  border-bottom: none;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.scroller-section
  .slider-content
  .slider-section
  .slider-carousel
  .main-div
  .img-section
  img {
  display: flex;
}
.scroller-section .slider-content .slider-section .image-section {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.scroller-section .slider-content .slider-section .image-section img {
  object-fit: cover;
  width: 100%;
}
@media (min-width: 1279px) and (max-width: 1620px) {
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .scroller-section .title .color-p-r {
    color: #fa441a;
  }
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-A025ED-FF0000 {
    background-color: #f00;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-1AC4FA-A025ED {
    background-color: #a025ed;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-E122E5-A4E522 {
    background-color: #a4e522;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .scroller-section .title .color-p-r {
    color: #fa441a;
  }
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 90%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-A025ED-FF0000 {
    background-color: #f00;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-1AC4FA-A025ED {
    background-color: #a025ed;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-E122E5-A4E522 {
    background-color: #a4e522;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .scroller-section .title {
    font-size: 27px;
  }

  .scroller-section .bottom-section .title {
    font-size: 1.5rem !important;
  }
  .scroller-section .alice-carousel__stage-item img {
    height: 150px !important;
  }
  .scroller-section .slider-content {
    width: 100%;
  }

  .scroller-section
    .slider-content
    .slider-section
    .slider-top-section
    .top-section
    .sub-title {
    width: 90%;
    margin: auto;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000 !important;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-top-section
    .top-section
    .drop-down-section {
    padding: 5px 0px;
    margin-left: 0;
    margin-right: 0;
  }
  .scroller-section .slider-content .slider-section .slider-carousel {
    width: 100%;
    margin: auto;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-A025ED-FF0000 {
    background-color: #f00;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-1AC4FA-A025ED {
    background-color: #a025ed;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .bg-color-E122E5-A4E522 {
    background-color: #a4e522;
  }
  .scroller-section
    .slider-content
    .slider-section
    .slider-carousel
    .carousel-root {
    width: 80%;
  }
}

.talk-section-content {
  position: relative;
}
.talk-section .span-text-color {
  color: #7b1fa2;
}
.talk-section-content .talk-section {
  background-color: #FCE4EC;

  background-size: cover;
  text-align: center;
  position: relative;
  padding-bottom: 3%;
}

.talk-section-content .talk-section .title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.talk-section-content .talk-section .title-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
}
.talk-section-content .talk-section .title-section .title .color-AE46F5-FA441A {
  color: #ae46f5;
}
.talk-section-content .talk-section .title-section .sub-title {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
}
.talk-section-content .talk-section .alice-carousel {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.talk-section-content .talk-section .slider-section .card {
  background-color: #e8eaf6 !important;
  min-height: 45vh;
  border: 1px solid #0d47a1;
  border-radius: 15px;
  padding: 15px;
  margin-left: 25px;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 85px;
  background-color: #fff;
  padding-top: 53px;
  margin-bottom: 10px;
  border-radius: 15px;
  position: relative;
}
.talk-section-content .talk-section .slider-section .card .main-image-section {
  width: 138px;
  margin: auto;
  position: absolute;
  top: -18%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .main-image-section
  .play-image-section {
  width: 40px;
  margin: auto;
  position: absolute;
  top: 45%;
  left: 34%;
  cursor: pointer;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .main-image-section
  .image-section {
  display: flex;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .main-image-section
  .image-section
  img {
  object-fit: cover;
  width: 100%;
}
.talk-section-content .talk-section .slider-section .card .card-des .title {
  font-style: normal;
  font-weight: 700;
  font-size: 25.64px;
  line-height: 36px;
  padding-top: 25px;
  padding-bottom: 8px;
}
.talk-section-content .talk-section .slider-section .card .card-des .sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18.64px;
  line-height: 26px;
  padding-bottom: 15px;
}
.talk-section-content .talk-section .slider-section .card .card-des .des-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14.64px;
  line-height: 26px;
}
.talk-section-content
  .talk-section
  .slider-section
  .card
  .card-des
  .card-star-img {
  position: absolute;
  bottom: 1rem;
  left: 30%;
}
.talk-section-content .title-bg-img-section {
  display: flex;
  justify-talk-section-content: center;
  position: absolute;
  top: -135px;
}
.talk-section-content .title-bg-img-section img {
  width: 100%;
}
.slider-section {
  margin-top: 15px;
}

@media (min-width: 1441px) and (max-width: 2260px) {
  .talk-section-content .talk-section {
    padding-top: 6rem;
  }
}
@media screen and (max-width: 968px) {
  .talk-section-content .alice-carousel__wrapper {
    padding: 0;
  }
  .talk-section-content .talk-section .title-section .sub-title {
    font-size: 1.1rem;
  }

  .talk-section-content .talk-section .slider-section .card{
    height: 370px;
  }
}
@media (min-width: 1250px) and (max-width: 1440px) {
  .talk-section-content .talk-section {
    padding-top: 11rem;
  }
  .talk-section-content .talk-section .slider-section .card {
    margin-top: 100px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 68px 10px 10px 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -15%;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 686px) and (max-width: 1250px) {
  .talk-section-content .talk-section {
    margin-bottom: 0;
    padding-top: 95px;
    margin-left: 0;
    width: 100%;
  }
  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }
  .talk-section-content .talk-section .slider-section .card {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -13%;
  }
  .talk-section-content .talk-section .left-img-section {
    bottom: -4%;
    left: -205px;
    max-width: 200px;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 568px) and (max-width: 686px) {
  .talk-section-content .talk-section {
    margin-bottom: 0;
    width: 100%;
    padding-top: 60px;
  }

  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }

  .talk-section-content .talk-section .slider-section .card {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -10%;
  }
  .talk-section-content .talk-section .slider-section .card .card-des .title {
    font-size: 0.8rem;
    line-height: 15px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .sub-title {
    font-size: 8px;
    line-height: 12px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .des-title {
    line-height: 13px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img {
    margin-top: 0;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img
    img {
    width: 25px;
  }
  .talk-section-content .talk-section .left-img-section {
    bottom: -13%;
    left: -3%;
    max-width: 200px;
    display: none;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 480px) and (max-width: 568px) {
  .talk-section-content .talk-section {
    margin-bottom: 0;
    padding-top: 50px;
    margin-left: 0;
    width: 100%;
  }
  .talk-section-content .talk-section .title-section {
    margin-bottom: 0rem;
  }

  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }

  .talk-section-content .talk-section .slider-section .card {
    width: 100%;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -14%;
  }
  .talk-section-content .talk-section .slider-section .card .card-des .title {
    font-size: 0.8rem;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .sub-title {
    font-size: 8px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .des-title {
    font-size: 15px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img {
    margin-top: 1.5rem;
  }
  .talk-section-content .talk-section .left-img-section {
    bottom: -5%;
    left: -3%;
    max-width: 120px;
    display: none;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}
@media (min-width: 0px) and (max-width: 480px) {
  .talk-section-content .alice-carousel__stage-item {
    width: 100% !important;
  }
  .talk-section-content .talk-section {
    margin-bottom: 0;
    padding-top: 35px;
    margin-left: 0;
    width: 100%;
  }
  .talk-section-content .talk-section .title-section {
    margin-bottom: 0rem;
  }

  .talk-section-content .talk-section .title .color-AE46F5-FA441A {
    color: #fa441a !important;
  }

  .talk-section-content .talk-section .slider-section .card {
    margin-left: 10px;
    margin-right: 10px;
    padding: 68px 10px 10px 10px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .main-image-section {
    top: -10%;
  }
  .talk-section-content .talk-section .slider-section .card .card-des .title {
    margin-top: 2rem;
    font-size: 0.8rem;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .sub-title {
    font-size: 8px;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .des-title {
    font-size: 0.8rem;
  }
  .talk-section-content
    .talk-section
    .slider-section
    .card
    .card-des
    .card-star-img {
    margin-top: 1.5rem;
  }
  .talk-section-content .talk-section .left-img-section {
    display: none;
  }
  .talk-section-content .title-bg-img-section {
    top: 0;
  }
}

.setsApart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fce4ec;
  padding-bottom: 6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(/static/media/ballonsBg.cd14859e.png);
}

.setaApart__headerWrapper {
  width: 100%;
  height: 320px;
  background: #6a1b9a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setaApart__header {
  width: 100%;
  height: 100%;
  background: url(/static/media/happyk.6855adcf.svg) left,
    url(/static/media/studentlap.5b703954.png) right;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  grid-gap: 10px;
  gap: 10px;
}

.setaApart__header > h1 {
  font-size: 2rem;
}

.setsApartContainer__wrapper {
  width: 100%;
  height: 100%;
}

.setsApartContainer {
  display: flex;
  justify-content: space-evenly;
}

.setsApartContainerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftDashedLine {
  height: 270px;
}

.centerdashedImage {
  height: 270px;
}

.rightDashedLine {
  height: 270px;
}

.leftImage {
  margin-top: -2rem;
  width: 70%;
}

.rightImage {
  margin-top: -2rem;
  width: 70%;
}

.centerImage {
  margin-top: -2rem;
  width: 70%;
}

/* .leftImage:hover,
.centerImage:hover,
.rightImage:hover {
  transform: scale(1.05);
} */

.setsApartContainerItemCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .setsApartContainerItem > img {
  object-fit: contain;
} */

@media screen and (max-width: 768px) {
  .setaApart__header > h1 {
    font-size: 1.5rem;
  }
  .setaApart__headerWrapper {
    height: 200px;
  }
  .setaApart__header {
    background-repeat: no-repeat;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(/static/media/happyk.6855adcf.svg) left no-repeat;
  }

  .leftDashedLine {
    height: 150px;
  }

  .centerdashedImage {
    margin-top: -15px;
    height: 150px;
  }

  .rightDashedLine {
    margin-top: -15px;
    height: 150px;
  }
  .setsApartContainer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .leftImage,
  .rightImage,
  .centerImage {
    width: 65%;
  }

  .setsApart {
    padding-bottom: 4rem;
  }
}

@media screen and ((max-width: 1080px) and (min-width: 768px)) {
  .leftDashedLine {
    display: inline;
  }

  .centerdashedImage {
    display: inline;
  }

  .rightDashedLine {
    display: inline;
  }
}

 .password {
	 background: url(/static/media/SignInBG.83fcd96c.png);
	 background-repeat: no-repeat;
	 height: 100vh;
}
 .password .passwordCotainer {
	 width: 100%;
	 height: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 5%;
	 gap: 5%;
}
 .password .passwordCotainer .passwordContainerLeft {
	 flex: 0.45 1;
	 height: 85vh;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 grid-gap: 1.5rem;
	 gap: 1.5rem;
}
 .password .passwordCotainer .passwordContainerLeft .logo {
	 object-fit: contain;
	 width: 40%;
}
 .password .passwordCotainer .passwordContainerLeft .cherriImage {
	 object-fit: contain;
	 width: 90%;
}
 .password .passwordCotainer .passwordContainerRight {
	 background-color: white;
	 width: 35%;
	 padding: 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 justify-content: flex-start !important;
	 border-radius: 16px;
   grid-gap: 1rem;
   gap: 1rem;
}
 .password .passwordCotainer .passwordContainerRight h1 {
	 font-size: 2rem;
	 font-weight: 600;
}
 .password .passwordCotainer .passwordContainerRight p {
	 font-size: 1rem;
	 cursor: pointer;
   margin-bottom: 2rem;
}

.password .passwordCotainer .passwordContainerRight input{
  margin-bottom: 2rem;
  width: 80%;
}

 .password .passwordCotainer .passwordContainerRight button {
	 width: 80% !important;
	 margin-top: 2rem;
	 width: -moz-fit-content;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 10px;
	 gap: 10px;
}
 .password .passwordCotainer .passwordContainerRight .passwordContainerRightBottom {
	 padding: 0 10px;
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
}
 .password .passwordCotainer .passwordContainerRight .passwordContainerRightBottom button {
	 width: 100% !important;
	 margin-bottom: 5px;
	 width: -moz-fit-content;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 10px;
	 gap: 10px;
}
 .password .passwordCotainer .passwordContainerRight .passwordContainerRightBottom p {
	 font-size: 0.8rem;
}

.signInForm__error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
 
@media screen and (max-width: 768px) {
  .password .passwordCotainer .passwordContainerLeft {
		display: none;
	}

	.password .passwordCotainer .passwordContainerRight {
		width: 80%;
	}
  .password .passwordCotainer .passwordContainerRight h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px) {

}
.phone {
  background: url(/static/media/SignInBG.83fcd96c.png);
  background-repeat: no-repeat;
  height: 100vh;
}
.phone .phoneCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5%;
  gap: 5%;
}
.phone .phoneCotainer .phoneContainerLeft {
  flex: 0.45 1;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.phone .phoneCotainer .phoneContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.phone .phoneCotainer .phoneContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.phone .phoneCotainer .phoneContainerRight {
  background-color: white;
  width: 35%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  grid-gap: 2rem;
  gap: 2rem;
}
.phone .phoneCotainer .phoneContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.phone .phoneCotainer .phoneContainerRight > p {
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.phone .phoneCotainer .phoneContainerRight input {
  width: 80%;
  margin-bottom: 1rem;
}

.phone .phoneCotainer .phoneContainerRight button {
  width: 80% !important;
  margin: 2rem 0;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom button {
  width: 100% !important;
  margin: 2rem 0;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.phone
  .phoneCotainer
  .phoneContainerRight
  .phoneContainerRightBottom
  .marginer {
  border-bottom: 1px solid gray;
  margin: 0.5rem 0;
  width: 100%;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom p {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .phone .phoneCotainer .phoneContainerLeft {
    display: none;
  }

  .phone .phoneCotainer .phoneContainerRight {
    width: 80%;
  }
  .phone .phoneCotainer .phoneContainerRight h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px) {
}

 .selectClass {
	 background: url(/static/media/SignInBG.83fcd96c.png);
	 background-repeat: no-repeat;
	 width: 100vw;
	 height: 100vh;
}
 .selectClass .selectClassCotainer {
	 width: 100%;
	 height: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 5%;
	 gap: 5%;
}
 .selectClass .selectClassCotainer .selectClassContainerLeft {
	 flex: 0.45 1;
	 height: 85vh;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 grid-gap: 1.5rem;
	 gap: 1.5rem;
}
 .selectClass .selectClassCotainer .selectClassContainerLeft .logo {
	 object-fit: contain;
	 width: 40%;
}
 .selectClass .selectClassCotainer .selectClassContainerLeft .cherriImage {
	 object-fit: contain;
	 width: 90%;
}
 .selectClass .selectClassCotainer .selectClassContainerRight {
	 background-color: white;
	 width: 35%;
	 padding: 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 border-radius: 16px;
   grid-gap: 2rem;
   gap: 2rem;
}
 .selectClass .selectClassCotainer .selectClassContainerRight h1 {
	 font-size: 2rem;
	 font-weight: 600;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 1rem;
	 gap: 1rem;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose button {
	 background: #b2ffc0;
	 box-shadow: 0px 0px 6.50559px rgba(0, 0, 0, 0.12);
	 color: #ac11fd;
	 padding: 10px 20px;
	 cursor: pointer;
	 font-weight: 700;
	 border: 3px solid #fff;
	 border-radius: 9px;
   outline-width: 0;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose .selectClassContainer__chooseClass {
	 border: 3px solid #31d95e;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose button:nth-child(odd) {
	 background: #fff2ab;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow {
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: space-evenly;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox {
	 align-items: center;
	 justify-content: center;
	 width: 8rem;
	 height: 8rem;
	 border: none;
	 outline-width: 0;
	 border-radius: 11px;
	 box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
	 cursor: pointer;
	 position: relative;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox svg {
	 position: absolute;
	 top: 10px;
	 right: 10px;
	 font-size: medium;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox p {
	 font-size: 1.2rem;
	 color: #ac11fd;
	 font-weight: 600;
	 text-align: center;
}
 .selectClass .selectClassCotainer .selectClassContainerRight > button {
	 margin: 2rem 0;
	 width: -moz-fit-content;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 grid-gap: 10px;
	 gap: 10px;
}
 
@media screen and (max-width: 768px) {
  .selectClass .selectClassCotainer .selectClassContainerLeft {
		display: none;
	}

	.selectClass .selectClassCotainer .selectClassContainerRight {
		width: 90%;
	}
  .selectClass .selectClassCotainer .selectClassContainerRight h1 {
    font-size: 1.4rem;
  }

	.selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox p {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 400px) {
	.selectClass .selectClassCotainer .selectClassContainerRight {
		width: 95%;
	}
}
.phone {
  background: url(/static/media/SignInBG.83fcd96c.png);
  background-repeat: no-repeat;
  height: 100vh;
}
.phone .phoneCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 5%;
  gap: 5%;
}
.phone .phoneCotainer .phoneContainerLeft {
  flex: 0.45 1;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}
.phone .phoneCotainer .phoneContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.phone .phoneCotainer .phoneContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.phone .phoneCotainer .phoneContainerRight {
  background-color: white;
  width: 35%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  grid-gap: 2rem;
  gap: 2rem;
}
.phone .phoneCotainer .phoneContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.phone .phoneCotainer .phoneContainerRight > p {
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.phone .phoneCotainer .phoneContainerRight input {
  width: 80%;
  margin-bottom: 1rem;
}

.phone .phoneCotainer .phoneContainerRight button {
  width: 80% !important;
  margin: 2rem 0;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom button {
  width: 100% !important;
  margin: 2rem 0;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.phone
  .phoneCotainer
  .phoneContainerRight
  .phoneContainerRightBottom
  .marginer {
  border-bottom: 1px solid gray;
  margin: 0.5rem 0;
  width: 100%;
}
.phone .phoneCotainer .phoneContainerRight .phoneContainerRightBottom p {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .phone .phoneCotainer .phoneContainerLeft {
    display: none;
  }

  .phone .phoneCotainer .phoneContainerRight {
    width: 80%;
  }
  .phone .phoneCotainer .phoneContainerRight h1 {
    font-size: 1.4rem;
  }
}


