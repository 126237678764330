.formikselect > select {
  appearance: none;
  color: white;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  transition: all 0.4s ease-in-out;
}

.formikselect.formikselectBlack > select {
  color: gray;
  width: 100%;
  font-size: 0.8rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.formikselect {
  padding: 0.25em 0.5em;
  border: 3px solid #37d0af;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 10px;
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  transition: all 0.4s ease-in-out;
}

.formikselect.formikselectBlack {
  padding: 7px 10px;
  border: 1px solid gray;
  width: 100%;
  margin-bottom: 0px;
}

select,
.formikselect:after {
  grid-area: select;
}

.formikselect::after {
  content: '';
  width: 0.7em;
  height: 0.4em;
  background-color: white;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

.formikselect.formikselectBlack::after {
  content: '';
  width: 0.7em;
  height: 0.4em;
  background-color: #6a5eea;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

select > option {
  width: 100%;
  color: black;
  font-size: 0.9rem;
  /* background-color: rgb(231, 230, 230); */
  border-radius: 20px;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}
