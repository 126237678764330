.App .display-main-section {
  display: block;
}
.App .none-main-section {
  display: none;
}
.App .display-header {
  display: block;
}
.App .menu-mobile {
  padding: 30px 20px;
}
.App .menu-mobile .closeIcon {
  text-align: end;
}
.App .menu-mobile .closeIcon button > svg {
  color: #fff;
  cursor: pointer;
}
.App .menu-mobile .menu-item {
  text-align: center;
  font-size: 1.5rem;
  padding: 30px 0;
}
.App .menu-mobile .menu-item a {
  text-decoration: none;
  color: #fff;
}
.App .menu-mobile .menu-item:hover {
  color: #dedede;
}
.App .none-header {
  display: none;
}
.App .full-div {
  overflow-x: hidden;
  overflow: hidden;
}
.App .full-div .header-component {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("./assets/images/heroBg.png");
  background: #760a98;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.App .full-div .header-component .set-bg-div {
  display: none;
}
