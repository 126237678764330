.ques {
  font-weight: 500;
}

.ques-ans-section .span-text-color {
  color: #ffc727;
}
.ques-ans-section {
  background-color: #6a1b9a;
  color: white !important;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.ques-ans-section .header-bg-section {
  display: flex;
  width: 80%;
  margin-left: auto;
}
.ques-ans-section .header-bg-section img {
  object-fit: cover;
  width: 100%;
}
.ques-ans-section .q-a-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ques-ans-section .q-a-section .left-section {
  width: 50%;
}
.ques-ans-section .q-a-section .left-section .image-section {
  display: flex;
  max-width: 560px;
  margin: auto;
}
.ques-ans-section .q-a-section .left-section .image-section img {
  width: 100%;
  object-fit: cover;
}
.ques-ans-section .q-a-section .right-section {
  width: 50%;
}
.ques-ans-section .q-a-section .right-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 54px;
  margin-bottom: 40px;
}
.ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
  color: #8d00ee;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content {
  max-width: 80%;
  margin-right: auto;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .ques-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 24px 26px;
  font-style: normal;
  font-size: 1rem;
  line-height: 32px;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .set-border {
  border: 0.924414px solid white;
  border-radius: 10px;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .active {
  background: #8d00ee;
  border: none;
  border-radius: 10px;
}


.ques-ans-section .q-a-section .right-section .ques-ans-content .cursor {
  cursor: pointer;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .cursor svg {
  stroke: white;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .displayAnswer {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 42px;
  margin-left: 2rem;
  margin-bottom: 20px;
}
.ques-ans-section .q-a-section .right-section .ques-ans-content .noneAnswer {
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ques-ans-section .q-a-section {
    flex-direction: column;
  }
  .ques-ans-section .q-a-section .left-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .left-section .image-section {
    width: 250px;
  }
  .ques-ans-section .q-a-section .right-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .right-section .title {
    text-align: center;
    margin-top: 20px;
  }
  .ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content {
    margin: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .ques-ans-section .q-a-section {
    flex-direction: column;
  }
  .ques-ans-section .q-a-section .left-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .left-section .image-section {
    width: 300px;
  }
  .ques-ans-section .q-a-section .right-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .right-section .title {
    text-align: center;
    margin-top: 20px;
  }
  .ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content {
    margin: auto;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .ques-ans-section .q-a-section {
    flex-direction: column;
  }
  .ques-ans-section .q-a-section .left-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .left-section .image-section {
    width: 200px;
  }
  .ques-ans-section .q-a-section .right-section {
    width: 100%;
  }
  .ques-ans-section .q-a-section .right-section .title {
    text-align: center;
    margin-top: 20px;
    font-size: 27px;
    line-height: 35px;
  }
  .ques-ans-section .q-a-section .right-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content {
    margin: auto;
  }
  .ques-ans-section .q-a-section .right-section .ques-ans-content .ques-div {
    font-size: 10px;
    line-height: 14px;
    padding: 6px 26px;
    align-items: center;
  }
  .ques-ans-section
    .q-a-section
    .right-section
    .ques-ans-content
    .displayAnswer {
    font-size: 10px;
    line-height: 14px;
  }
}
