.passwordField {
  outline-width: 0;
  border: 1px solid gray;
  padding: 8px 20px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
}

.passwordField__input {
  outline-width: 0;
  border: none;
  flex: 1;
}

.passwordField > .MuiSvgIcon-root {
  font-size: medium;
  color: #6a5eea;
  cursor: pointer;
}
