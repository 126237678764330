 .selectClass {
	 background: url('../../../assets/images/auth/SignInBG.png');
	 background-repeat: no-repeat;
	 width: 100vw;
	 height: 100vh;
}
 .selectClass .selectClassCotainer {
	 width: 100%;
	 height: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 5%;
}
 .selectClass .selectClassCotainer .selectClassContainerLeft {
	 flex: 0.45;
	 height: 85vh;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 gap: 1.5rem;
}
 .selectClass .selectClassCotainer .selectClassContainerLeft .logo {
	 object-fit: contain;
	 width: 40%;
}
 .selectClass .selectClassCotainer .selectClassContainerLeft .cherriImage {
	 object-fit: contain;
	 width: 90%;
}
 .selectClass .selectClassCotainer .selectClassContainerRight {
	 background-color: white;
	 width: 35%;
	 padding: 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 border-radius: 16px;
   gap: 2rem;
}
 .selectClass .selectClassCotainer .selectClassContainerRight h1 {
	 font-size: 2rem;
	 font-weight: 600;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 1rem;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose button {
	 background: #b2ffc0;
	 box-shadow: 0px 0px 6.50559px rgba(0, 0, 0, 0.12);
	 color: #ac11fd;
	 padding: 10px 20px;
	 cursor: pointer;
	 font-weight: 700;
	 border: 3px solid #fff;
	 border-radius: 9px;
   outline-width: 0;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose .selectClassContainer__chooseClass {
	 border: 3px solid #31d95e;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainer__choose button:nth-child(odd) {
	 background: #fff2ab;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow {
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: space-evenly;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox {
	 align-items: center;
	 justify-content: center;
	 width: 8rem;
	 height: 8rem;
	 border: none;
	 outline-width: 0;
	 border-radius: 11px;
	 box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
	 cursor: pointer;
	 position: relative;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox svg {
	 position: absolute;
	 top: 10px;
	 right: 10px;
	 font-size: medium;
}
 .selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox p {
	 font-size: 1.2rem;
	 color: #ac11fd;
	 font-weight: 600;
	 text-align: center;
}
 .selectClass .selectClassCotainer .selectClassContainerRight > button {
	 margin: 2rem 0;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 10px;
}
 
@media screen and (max-width: 768px) {
  .selectClass .selectClassCotainer .selectClassContainerLeft {
		display: none;
	}

	.selectClass .selectClassCotainer .selectClassContainerRight {
		width: 90%;
	}
  .selectClass .selectClassCotainer .selectClassContainerRight h1 {
    font-size: 1.4rem;
  }

	.selectClass .selectClassCotainer .selectClassContainerRight .selectClassContainerRightRow .selectClassContainerRightBox p {
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 400px) {
	.selectClass .selectClassCotainer .selectClassContainerRight {
		width: 95%;
	}
}