.setsApart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fce4ec;
  padding-bottom: 6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/ballonsBg.png");
}

.setaApart__headerWrapper {
  width: 100%;
  height: 320px;
  background: #6a1b9a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setaApart__header {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/landingpage/happyk.svg") left,
    url("../../assets/images/landingpage/studentlap.png") right;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 10px;
}

.setaApart__header > h1 {
  font-size: 2rem;
}

.setsApartContainer__wrapper {
  width: 100%;
  height: 100%;
}

.setsApartContainer {
  display: flex;
  justify-content: space-evenly;
}

.setsApartContainerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftDashedLine {
  height: 270px;
}

.centerdashedImage {
  height: 270px;
}

.rightDashedLine {
  height: 270px;
}

.leftImage {
  margin-top: -2rem;
  width: 70%;
}

.rightImage {
  margin-top: -2rem;
  width: 70%;
}

.centerImage {
  margin-top: -2rem;
  width: 70%;
}

/* .leftImage:hover,
.centerImage:hover,
.rightImage:hover {
  transform: scale(1.05);
} */

.setsApartContainerItemCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .setsApartContainerItem > img {
  object-fit: contain;
} */

@media screen and (max-width: 768px) {
  .setaApart__header > h1 {
    font-size: 1.5rem;
  }
  .setaApart__headerWrapper {
    height: 200px;
  }
  .setaApart__header {
    background-repeat: no-repeat;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../assets/images/landingpage/happyk.svg") left no-repeat;
  }

  .leftDashedLine {
    height: 150px;
  }

  .centerdashedImage {
    margin-top: -15px;
    height: 150px;
  }

  .rightDashedLine {
    margin-top: -15px;
    height: 150px;
  }
  .setsApartContainer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .leftImage,
  .rightImage,
  .centerImage {
    width: 65%;
  }

  .setsApart {
    padding-bottom: 4rem;
  }
}

@media screen and ((max-width: 1080px) and (min-width: 768px)) {
  .leftDashedLine {
    display: inline;
  }

  .centerdashedImage {
    display: inline;
  }

  .rightDashedLine {
    display: inline;
  }
}
