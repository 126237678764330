.bottom-slider-wrapper {
  width: 100%;
  padding-top: 4rem;
  background-color: #6a1b9a;
}
.bottom-slider-wrapper .left-section .span-text-color {
  color: #ffc727;
}
.bottom-slider {
  margin-bottom: 100px;
  max-width: 80%;
  margin: auto;
  margin-top: 5rem;
}
.getthe {
  color: #212121;
}
.bottom-slider .top-section {
  text-align: center;
  margin-bottom: 110px;
}
.bottom-slider .top-section .title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 54px;
  margin-bottom: 36px;
}
.bottom-slider .top-section .title .color-AE46F5-FA441A {
  color: #ae46f5;
}
.bottom-slider .top-section .button-section {
  max-width: 250px;
  margin: auto;
  position: relative;
}
.bottom-slider .top-section .button-section .button {
  background: linear-gradient(180deg, #ae46f5 0%, rgba(145, 2, 238, 0.21) 100%);
  border-radius: 20px;
}
.bottom-slider .top-section .button-section .button .title {
  font-style: normal;
  font-weight: bold;
  font-size: 16.03px;
  line-height: 12px;
  color: #fff;
  padding: 15px 15px 20px 40px;
  text-align: center;
}
.bottom-slider .top-section .button-section .button .button-img {
  position: absolute;
  left: 20px;
}
.bottom-slider .top-section .link-button {
  max-width: 400px;
  margin: auto;
}
.bottom-slider .top-section .link-button .link {
  display: flex;
  justify-content: space-between;
}
.bottom-slider .top-section .link-button .link .mac-button {
  width: 170px;
}
.bottom-slider .top-section .link-button .link .mac-button img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .top-section .link-button .link .windows-button {
  width: 170px;
}
.bottom-slider .top-section .link-button .link .windows-button img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .link-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-slider .link-info .left-section {
  width: 50%;
}
.bottom-slider .link-info .left-section .title {
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 73px;
  margin-bottom: 2rem;
}
.bottom-slider .link-info .left-section .sub-title {
  color: white;
  font-style: normal;
  font-weight: 300;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  line-height: 61px;
}
.bottom-slider .link-info .left-section .link-button {
  display: flex;
}
.bottom-slider .link-info .left-section .link-button a {
  text-decoration: none !important;
  color: inherit;
}
.bottom-slider .link-info .left-section .link-button .button-section {
  margin: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bottom-slider .link-info .left-section .link-button .button-section .icon-div {
  width: 30px;
  display: flex;
  justify-content: center;
}
.bottom-slider
  .link-info
  .left-section
  .link-button
  .button-section
  .icon-div
  img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .link-info .left-section .link-button .button-section .content {
  margin-left: 10px;
  line-height: 1;
  font-size: 13px;
}
.bottom-slider
  .link-info
  .left-section
  .link-button
  .button-section
  .content
  .con-sub-title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}
.bottom-slider .link-info .right-section {
  width: 50%;
}
.bottom-slider .link-info .right-section .image-section {
  max-width: 500px;
  margin: auto;
}
.bottom-slider .link-info .right-section .image-section img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .main-slider-section {
  position: relative;
}
.bottom-slider .mobile-img-section {
  position: absolute;
  top: 1px;
  z-index: 2;
  width: 200px;
  display: flex;
  justify-content: center;
  transform: translate(-50%);
  left: 50%;
}
.bottom-slider .mobile-img-section img {
  width: 100%;
  object-fit: cover;
}
.bottom-slider .main {
  min-height: 200px;
  height: 400px;
}
.bottom-slider .main .img-section {
  position: absolute;
  top: 7px;
  z-index: 1;
  width: 170px;
  margin: auto;
  height: 355px;
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translate(-50%);
}
.bottom-slider .main .img-section img {
  width: 100%;
  object-fit: cover;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .bottom-slider .top-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .bottom-slider .top-section .button-section .button {
    background: linear-gradient(
      180deg,
      #fa441a 0%,
      rgba(250, 68, 26, 0.4) 100%
    );
  }
  .bottom-slider .link-info {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
  .bottom-slider .link-info .left-section {
    width: 90%;
    margin-top: 20px;
  }
  .bottom-slider .link-info .left-section .title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .bottom-slider .link-info .left-section .sub-title {
    font-size: 22px;
    line-height: 25px;
  }
  .bottom-slider .link-info .left-section .link-button {
    justify-content: center;
    width: 240px;
    margin: auto;
    flex-direction: column;
  }
  .bottom-slider .link-info .right-section {
    width: 95%;
  }
  .bottom-slider .link-info .right-section .image-section {
    max-width: 250px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .bottom-slider .top-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .bottom-slider .top-section .button-section .button {
    background: linear-gradient(
      180deg,
      #fa441a 0%,
      rgba(250, 68, 26, 0.4) 100%
    );
  }
  .bottom-slider .link-info {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
  .bottom-slider .link-info .left-section {
    width: 90%;
    margin-top: 20px;
  }
  .bottom-slider .link-info .left-section .title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .bottom-slider .link-info .left-section .sub-title {
    font-size: 22px;
    line-height: 25px;
  }
  .bottom-slider .link-info .left-section .link-button {
    justify-content: center;
    width: 240px;
    margin: auto;
    flex-direction: column;
  }
  .bottom-slider .link-info .right-section {
    width: 95%;
  }
  .bottom-slider .link-info .right-section .image-section {
    max-width: 250px;
  }
}
@media (min-width: 0px) and (max-width: 767px) {
  .bottom-slider {
    padding-bottom: 50px;
  }
  .bottom-slider .top-section {
    margin-bottom: 50px;
  }
  .bottom-slider .top-section .title {
    font-size: 27px;
    line-height: 54px;
    margin-bottom: 20px;
  }
  .bottom-slider .top-section .title .color-AE46F5-FA441A {
    color: #fa441a;
  }
  .bottom-slider .top-section .button-section .button {
    background: linear-gradient(
      180deg,
      #fa441a 0%,
      rgba(250, 68, 26, 0.4) 100%
    );
  }
  .bottom-slider .top-section .link-button {
    max-width: 210px;
  }
  .bottom-slider .top-section .link-button .link .mac-button {
    max-width: 95px;
  }
  .bottom-slider .top-section .link-button .link .windows-button {
    max-width: 95px;
  }
  .bottom-slider .link-info {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
  .bottom-slider .link-info .left-section {
    width: 90%;
    margin-top: 20px;
  }
  .bottom-slider .link-info .left-section .title {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .bottom-slider .link-info .left-section .sub-title {
    font-size: 22px;
    line-height: 25px;
  }
  .bottom-slider .link-info .left-section .link-button {
    justify-content: center;
    width: 240px;
    margin: auto;
    flex-direction: column;
  }
  .bottom-slider .link-info .right-section {
    width: 95%;
  }
  .bottom-slider .link-info .right-section .image-section {
    max-width: 250px;
  }
}
