 .password {
	 background: url('../../../assets/images/auth/SignInBG.png');
	 background-repeat: no-repeat;
	 height: 100vh;
}
 .password .passwordCotainer {
	 width: 100%;
	 height: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 5%;
}
 .password .passwordCotainer .passwordContainerLeft {
	 flex: 0.45;
	 height: 85vh;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 gap: 1.5rem;
}
 .password .passwordCotainer .passwordContainerLeft .logo {
	 object-fit: contain;
	 width: 40%;
}
 .password .passwordCotainer .passwordContainerLeft .cherriImage {
	 object-fit: contain;
	 width: 90%;
}
 .password .passwordCotainer .passwordContainerRight {
	 background-color: white;
	 width: 35%;
	 padding: 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 justify-content: flex-start !important;
	 border-radius: 16px;
   gap: 1rem;
}
 .password .passwordCotainer .passwordContainerRight h1 {
	 font-size: 2rem;
	 font-weight: 600;
}
 .password .passwordCotainer .passwordContainerRight p {
	 font-size: 1rem;
	 cursor: pointer;
   margin-bottom: 2rem;
}

.password .passwordCotainer .passwordContainerRight input{
  margin-bottom: 2rem;
  width: 80%;
}

 .password .passwordCotainer .passwordContainerRight button {
	 width: 80% !important;
	 margin-top: 2rem;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 10px;
}
 .password .passwordCotainer .passwordContainerRight .passwordContainerRightBottom {
	 padding: 0 10px;
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
}
 .password .passwordCotainer .passwordContainerRight .passwordContainerRightBottom button {
	 width: 100% !important;
	 margin-bottom: 5px;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 10px;
}
 .password .passwordCotainer .passwordContainerRight .passwordContainerRightBottom p {
	 font-size: 0.8rem;
}

.signInForm__error {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
 
@media screen and (max-width: 768px) {
  .password .passwordCotainer .passwordContainerLeft {
		display: none;
	}

	.password .passwordCotainer .passwordContainerRight {
		width: 80%;
	}
  .password .passwordCotainer .passwordContainerRight h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px) {

}