.navbar {
  width: 100%;
  height: 95px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  z-index: 999 !important;
  position: sticky;
}

.navbar.active {
  -webkit-backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background: hsla(0, 0%, 100%, 0.5);
  box-shadow: 0 25px 26px -1px rgb(0 0 0 / 10%);
}

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.navbar-container > ul {
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0px;
}

.navbar-logo {
  text-decoration: none;
  color: #3f51b5;
  justify-self: start;
  margin-left: 2.6rem;
  cursor: pointer;
}

.navbar-logo > img {
  width: auto;
  height: 75px;
}

.nav-menu {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, auto);
  grid-gap: 0px 10px;
  list-style: none;
  text-align: center;
  /* min-width: 70vw; */
  justify-content: end;
  margin: 0 2rem 0 0;
}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.ReactSelect {
  width: 8rem;
}

.nav-links {
  color: #000;
  text-decoration: none;
  padding: 0rem 1rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
  transition: all 50ms ease-in-out;
}

.nav-links:hover {
  color: rgb(220, 206, 206) !important;
}

.fa-bars {
  color: black;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.navbar__button {
  background-color: transparent;
  border: 2px solid green;
  padding: 3px 20px;
  cursor: pointer;
  border-radius: 9px;
  margin: 0 0.25rem;
}

.navbar__button > button {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
}

.navbar__buttons {
  display: flex;
  gap: 5px;
}

.navbarUser__details {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-right: 1rem;
}

.navbarUser__details > p {
  color: #000;
  text-decoration: none;
  letter-spacing: 0.01rem;
  font-weight: 500;
}

.navbarUser__details > button > .MuiSvgIcon-root {
  color: gray;
  cursor: pointer;
  /* padding: 0rem 0 0 1rem; */
}

.notification__popup {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.popOverContainer {
  padding-top: 3rem;
  position: absolute;
  top: 1rem;
  right: -6rem;
  overflow-y: scroll;
}

.coinsEarned {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px solid gray;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-logo {
    text-decoration: none;
    color: #3f51b5;
    justify-self: start;
    margin-left: 10px;
    cursor: pointer;
    transform: translate(50%, 50%);
  }

  .navbar-logo > img {
    width: auto;
    height: 40px;
    margin-top: 0;
  }

  .nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    position: absolute;
    top:95px;

    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow-x: hidden;
  }

  .nav-menu.active {
    border-top: 1px solid #fce4ec;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
  }

  .nav-links {
    margin: auto;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-item:hover {
    width: 60vw;
    border-radius: 20px;
    color: #242424;
    border-radius: 0;
  }

  .nav-links:hover {
    color: #242424;
    font-weight: 700;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    /* transform: translate(15%, 25%); */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
    border-radius: 4px;
    width: 60vw;
    text-decoration: none;
    font-size: 1.2rem;
    background-color: transparent;
    color: white;
    padding: 14px 14px;
    border: 1px solid lightgrey;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #d8d7d7;
    color: #242424;
    transition: 250ms;
    font-weight: 700;
  }
  .navbar__button {
    display: none;
  }
}
