@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.select {
  font-weight: 700;
  color: black;
  text-align: center;
}

.css-tj5bde-Svg {
  color: black;
}

.css-2613qy-menu {
  z-index: 999;
  min-height: 100%;
  /* max-width: 100%; */
}
