.otp {
  background: url("../../../assets/images/auth/SignInBG.png");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}
.otp .otpCotainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.otp .otpCotainer .otpContainerLeft {
  flex: 0.45;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.otp .otpCotainer .otpContainerLeft .logo {
  object-fit: contain;
  width: 40%;
}
.otp .otpCotainer .otpContainerLeft .cherriImage {
  object-fit: contain;
  width: 90%;
}
.otp .otpCotainer .otpContainerRight {
  background-color: white;
  width: 35%;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start !important;
  border-radius: 16px;
  position: relative;
  gap: 1.5rem;
}
.otp .otpCotainer .otpContainerRight h1 {
  font-size: 2rem;
  font-weight: 600;
}
.otp .otpCotainer .otpContainerRight p {
  font-size: 1rem;
}
.otp .otpCotainer .otpContainerRight button {
  margin-top: 2rem;
  width: fit-content;
  padding: 6px 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom {
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom .marginer {
  border-bottom: 1px solid gray;
  width: 100%;
}
.otp .otpCotainer .otpContainerRight .otpContainerRightBottom p {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .otp .otpCotainer .otpContainerLeft {
    display: none;
  }

  .otp .otpCotainer .otpContainerRight {
    width: 80%;
  }
  .otp .otpCotainer .otpContainerRight h1 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 400px) {
}
