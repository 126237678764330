 .gender {
	 background: url('../../../assets/images/auth/SignInBG.png');
	 background-repeat: no-repeat;
	 width: 100vw;
	 height: 100vh;
}
 .gender .genderCotainer {
	 width: 100%;
	 height: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 5%;
}
 .gender .genderCotainer .genderContainerLeft {
	 flex: 0.5;
	 height: 75vh;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
}
 .gender .genderCotainer .genderContainerLeft .logo {
	 object-fit: contain;
	 width: 40%;
}
 .gender .genderCotainer .genderContainerLeft .cherriImage {
	 object-fit: contain;
	 width: 90%;
}
 .gender .genderCotainer .genderContainerRight {
	 height: 85vh;
	 background-color: white;
	 width: 35%;
	 padding: 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 flex-direction: column;
	 gap: 3rem;
	 border-radius: 16px;
}
 .gender .genderCotainer .genderContainerRight h1 {
	 font-size: 2em;
	 font-weight: 600;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow {
	 width: 100%;
	 display: flex;
	 align-items: center;
	 justify-content: space-evenly;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow .genderContainerRightBox {
	 width: 8rem;
	 height: 8rem;
	 border: none;
	 outline-width: 0;
	 border-radius: 11px;
	 box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #fff;
	 cursor: pointer;
	 position: relative;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow .genderContainerRightBox svg {
	 position: absolute;
	 top: 10px;
	 right: 10px;
	 font-size: medium;
}
 .gender .genderCotainer .genderContainerRight .genderContainerRightRow .genderContainerRightBox p {
	 font-size: 1.2rem;
	 color: #ac11fd;
	 font-weight: 600;
	 text-align: center;
}
 .gender .genderCotainer .genderContainerRight button {
	 margin-top: 3rem;
	 width: fit-content;
	 padding: 6px 2rem;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 gap: 10px;
}
 
@media screen and (max-width: 768px) {
  .gender .genderCotainer .genderContainerLeft {
		display: none;
	}

	.gender .genderCotainer .genderContainerRight {
		width: 80%;
	}
	.gender .genderCotainer .genderContainerRight h1 {
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 400px) {

}
