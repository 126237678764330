.memberCard {
  width: 20rem;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(33 42 57 / 18%) 0px 12px 12px -8px,
    rgb(33 42 57 / 8%) 0px -2px 12px -2px;
  border-radius: 20px;
  margin: 0 0 10px 0;
}

.memberCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.memberCardContainer {
  position: relative;
  /* width: 17rem; */
}

.memberCardContainer__Content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.memberCardContainer:hover .memberCardContainer__Content {
  opacity: 1;
}

.memberCardContainer > img {
  width: 20rem;
  height: 50vh;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 1s ease-in-out;
}

.memberCardContainer__Content > p {
  font-weight: 500;
  margin: 0.15rem 0;
  text-align: center;
  color: gray;
}

.memberCardBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 10px;
}

.memberCardBottom > h1 {
  font-weight: 700;
  margin: 0.15rem 0;
  font-size: 1.1rem;
  color: black;
}

@media screen and (max-width: 1200px) {
  .memberCard {
    height: 100%;
    width: 300px;
  }
  .memberCardContainer > img {
    width: 300px;
    height: auto;
    border-radius: 25px;
  }
  .memberCardContainer__Content > p {
    font-size: 0.7rem;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .memberCard {
    width: 200px;
    height: 100%;
  }
  .memberCardContainer > img {
    width: 200px;
    height: auto;
    border-radius: 25px;
  }
  .memberCardContainer__Content > p {
    font-size: 0.7rem;
    text-align: center;
  }

  .memberCardBottom > h1 {
    font-size: 0.9rem;
  }

  .memberCardBottom > p {
    font-size: 0.7rem;
  }
}
