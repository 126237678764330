.learning-potential-section {
  background-color: #6a1b9a;
  padding-top: 20px;
  padding-bottom: 20px;
}

.learning-potential-section .learning-section {
  max-width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.learning-potential-section .learning-section .left-section {
  width: 50%;
}
.learning-potential-section .learning-section .left-section .ul-div {
  display: flex;
  margin-top: 15px;
  justify-content: flex-start;
  align-items: center;
  color: white;
}
.learning-potential-section .learning-section .left-section .ul-div .img-div {
  padding-top: 5px;
}
.learning-potential-section .learning-section .left-section .ul-div img {
  width: 10px;
  height: 10px;
}
.learning-potential-section .learning-section .left-section .ul-div .li-text {
  font-style: normal;
  font-weight: 400;
  font-size: 18.64px;
  line-height: 26px;
  margin-left: 15px;
  text-align: left;
}
.learning-potential-section .learning-section .left-section .title {
  font-style: normal;
  font-weight: 600;
  color: white;
  font-size: 2.2rem;
  line-height: 65px;
}
.learning-potential-section .learning-section .left-section .title .color-p-r {
  color: #ae46f5;
}
.learning-potential-section .learning-section .left-section .sub-title {
  margin-top: 1rem;
  font-style: normal;
  font-weight: 400;
  color: white;

  font-size: 18.64px;
  line-height: 36px;
}
.learning-potential-section .learning-section .left-section .button-section {
  margin-top: 2rem;
}
.learning-potential-section .learning-section .left-section .button-section a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  border-radius: 22px;
  width: fit-content;
  color: black;
  text-decoration: none;
  background: #FFEB3B;
  padding: 8px 28px;
  transition: 130ms all ease-in;
}
.learning-potential-section
  .learning-section
  .left-section
  .button-section
  a:hover {
    background: #f8e005;

}

.learning-potential-section
  .learning-section
  .left-section
  .button-section
  a
  .svg-button {
  fill: #fff;
  margin-left: 3px;

  border-radius: 50%;
}
.learning-potential-section .learning-section .right-section {
  width: 45%;
  margin: 5%;
}
.learning-potential-section .learning-section .right-section img {
  object-fit: cover;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .learning-potential-section .learning-section {
    flex-direction: column-reverse;
  }
  .learning-potential-section .learning-section .left-section {
    width: 90%;
    text-align: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .title
    .color-p-r {
    color: #fa441a;
  }
  .learning-potential-section .learning-section .left-section .button-section {
    display: flex;
    justify-content: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button {
    background-color: #fa441a;
    color: #fff;
    position: relative;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button
    .svg-button {
    margin-left: 3px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -25px;
    font-size: 55px;
  }
  .learning-potential-section .learning-section .right-section {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .learning-potential-section .learning-section {
    flex-direction: column-reverse;
  }
  .learning-potential-section .learning-section .left-section {
    width: 90%;
    text-align: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .title
    .color-p-r {
    color: #fa441a;
  }
  .learning-potential-section .learning-section .left-section .button-section {
    display: flex;
    justify-content: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button {
    background-color: #fa441a;
    color: #fff;
    position: relative;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button
    .svg-button {
    margin-left: 3px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -25px;
    font-size: 55px;
  }
  .learning-potential-section .learning-section .right-section {
    width: 90%;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .learning-potential-section .learning-section {
    flex-direction: column-reverse;
    padding: 3rem 0;
  }
  .learning-potential-section .learning-section .left-section {
    width: 90%;
    text-align: center;
  }
  .learning-potential-section .learning-section .left-section .title {
    font-size: 27px;
    line-height: 44px;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .title
    .color-p-r {
    color: #fa441a;
  }
  .learning-potential-section .learning-section .left-section .sub-title {
    font-size: 11.64px;
    line-height: 15px;
  }
  .learning-potential-section .learning-section .left-section .button-section {
    display: flex;
    justify-content: center;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button {
    background-color: #fa441a;
    color: #fff;
    position: relative;
    padding: 4.5px 30px 4.5px 15px;
    font-size: 21px;
    line-height: 30px;
  }
  .learning-potential-section
    .learning-section
    .left-section
    .button-section
    button
    .svg-button {
    margin-left: 3px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    right: -20px;
    font-size: 40px;
  }
  .learning-potential-section .learning-section .right-section {
    width: 90%;
  }
}
