@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: auto;
}

a {
  text-decoration: none;
}
.color-1A1A1A {
  color: #1a1a1a;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.cursor-pointer {
  cursor: pointer;
}
html,
body {
  overflow-x: clip;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to right bottom, #c8c7ca, #dbe5f0);
  border-radius: 25px;
}

.skeleton {
  opacity: 0.9;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}

@keyframes skeleton-loading {
  0% {
    background-position: 0% 50%;
    background-color: hsl(0, 0%, 100%);
  }
  50% {
    background-position: 100% 50%;
    background-color: hsl(200, 2%, 90%);
  }
  100% {
    background-position: 0% 50%;
    background-color: hsl(200, 20%, 95%);
  }
}

.sweet-loading {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.custom_fc_frame {
  right: 30px !important;
  bottom: 50px !important;
}

@media screen and (max-width: 1296px) {
  .custom_fc_frame {
    right: 10px !important;
    bottom: 100px !important;
  }
}

.invisible {
  visibility: hidden;
}

.alice-carousel__dots-item.__active {
  background-color: #760a98 !important;
}
.alice-carousel__dots-item.__inactive {
  background-color: grey !important;
}