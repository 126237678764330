.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  /* z-index: 100; */
  background: white !important;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  width: 25vw;
  min-height: 50vh;
  border-radius: 26px;
}

.modal-bg {
  padding: 1rem;
  border-radius: 26px;
  z-index: 1200;
  background: url('../../assets/images/popup/popupBg.svg');
  background-size: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.5;
  cursor: pointer;
  border: none;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.2rem 0.6rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}

.modal-body {
  margin-top: 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-body p {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  text-align: center;
  margin-top: 0.6rem;
}

.modal-body > button,
.modal-button {
  /* position: absolute; */
  /* bottom: 1rem; */
  color: #fff;
  text-align: center;
  margin-top: 1.5rem;
  border: none;
  border-radius: 3px;
  padding: 1rem 2rem;
  width: 50%;
  background: linear-gradient(267.1deg, #a310fd -7.31%, #d311fd 109.39%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
}

@media screen and (max-width: 1296px) {
  .modal {
    width: 50vw;
  }
}

@media screen and (max-width: 768px) {
  .modal {
    width: 70vw;
  }
}

@media screen and (max-width: 500px) {
  .modal {
    width: 90vw;
  }
}

@media screen and (max-width: 360px) {
  .modal {
    width: 90vw;
  }
}
