.card {
  transition: all 120ms ease-in;
}

.span-text-color {
  color: #7B1FA2;
}
.card:hover {
  transform: scale(1.04);
}

.card-section {
  background-color:#FCE4EC;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
}
.card-section .slider {
  display: none !important;
}
.card-section .title {
  font-style: normal;
  font-weight: 600;
  width: 100%;
  font-size: 2.2rem;
  line-height: 62px;
  margin-bottom: 4rem;
  align-items: center;
  justify-content: center;

  text-align: center;
  flex-direction: column;
  display: flex;
}
.card-section .card-content {
  display: flex;
  justify-content: center;
}
.card-section .card-content .card {
  background-color: #E8EAF6;
  border-radius: 20px;
  border: 1px solid black;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  max-width: 400px;
  padding: 50px 30px;
  text-align: center;
}
.card-section .card-content .card .image-section {
  max-height: 185px;
}

.card-section .card-content .card .title-section {
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 40px;
  margin-top: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  margin: auto;
}
.card-section .card-content .card .des-section {
  font-style: normal;
  font-weight: 400;
  font-size: 14.64px;
  line-height: 29px;
  margin-top: 7px;
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .card-section .card-content .card {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .card-section .title .color-cherri {
    color: #fa441a;
  }
  .card-section .card-content {
    display: block;
  }
  .card-section .card-content .card {
    margin: 2rem auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .card-section .title .color-cherri {
    color: #fa441a;
  }
  .card-section .card-content {
    display: block;
  }
  .card-section .card-content .card {
    margin: 2rem auto;
  }
}
@media (min-width: 100px) and (max-width: 767px) {
  .card-section .title {
    font-size: 27px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .card-section .title .color-cherri {
    color: #fa441a;
  }
  .card-section .display-slider-card {
    display: none;
  }
  .card-section .slider {
    display: flex !important;
  }
  .card-section .card-content .card {
    margin: 2rem 10px;
  }
  .card-section .card-content .card .title-section {
    font-size: 18.0467px;
    line-height: 29px;
  }
  .card-section .card-content .card .des-section {
    font-size: 10px;
    line-height: 18px;
  }
}
