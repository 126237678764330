.footer-section {
  margin: 90%;
  margin: auto;
  background-color: #6A1B9A;
  
}
.footer-section .footer-main-section {
  display: flex;
  justify-content: space-between;
}
.footer-section .footer-main-section .center-section {
  text-align: center;
  margin: auto;
}
.img-div > img {
  width: 6rem;
}
.footer-section .footer-main-section .center-section .des-section {
  width: 70%;
  margin: 25px auto 10px auto;
  font-style: normal;
  font-weight: 400;
  font-size: 15.6417px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
}
.footer-section .footer-main-section .center-section .sub-des-section {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 54px;
  text-align: center;
}
.footer-section .footer-main-section .center-section .link-button-section {
  margin-top: 10px;
  margin-bottom: 10px;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button {
  display: flex;
  justify-content: center;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  a {
  text-decoration: none;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section {
  margin: 5px;
  padding: 10px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
  cursor: pointer;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  img {
  width: 60%;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .icon-div {
  width: 40px;
  display: flex;
  justify-content: center;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .content {
  margin-left: 10px;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .content
  .con-sub-title {
  font-weight: 600;
  font-size: 0.8rem;
}
.footer-section
  .footer-main-section
  .center-section
  .link-button-section
  .link-button
  .button-section
  .content
  .con-sub-2-title {
  font-size: 0.8rem;
}
.footer-section .footer-main-section .f1 {
  margin: auto;
}
.footer-section .footer-main-section .f1 img {
  width: 100%;
}
.footer-section .footer-main-section .f3 {
  margin: auto;
}
.footer-section .footer-main-section .f3 img {
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 150px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 200px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 150px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 200px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 2%;
    left: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .center-section .img-div img {
    width: 6rem;
  }
  .footer-section .footer-main-section .center-section .des-section {
    font-size: 9px;
    line-height: 15px;
  }
  .footer-section .footer-main-section .center-section .sub-des-section {
    font-size: 14px;
    line-height: 20px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}
@media (min-width: 100px) and (max-width: 480px) {
  .footer-section .footer-main-section {
    position: relative;
  }
  .footer-section .footer-main-section .f1 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 2%;
    left: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .f3 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 10%;
    right: 0;
    width: 75px;
  }
  .footer-section .footer-main-section .center-section .img-div img {
    width: 6rem;
  }
  .footer-section .footer-main-section .center-section .des-section {
    font-size: 9px;
    line-height: 15px;
  }
  .footer-section .footer-main-section .center-section .sub-des-section {
    font-size: 14px;
    line-height: 20px;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button {
    justify-content: center;
    width: 175px;
    margin: auto;
    flex-direction: column;
  }
  .footer-section
    .footer-main-section
    .center-section
    .link-button-section
    .link-button
    .button-section {
    padding: 5px;
  }
}
